var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "", "pb-5": "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-tabs",
                {
                  attrs: { "slider-color": "secondary" },
                  model: {
                    value: _vm.activeTab,
                    callback: function($$v) {
                      _vm.activeTab = $$v
                    },
                    expression: "activeTab"
                  }
                },
                [
                  _c(
                    "v-tab",
                    {
                      staticClass: "tab__filled",
                      class: { active: _vm.activeTab === "tab-calculation" },
                      attrs: { href: "#tab-calculation" }
                    },
                    [_vm._v(" Calculatie ")]
                  ),
                  _c(
                    "v-tab",
                    {
                      staticClass: "tab__filled",
                      class: { active: _vm.activeTab === "tab-custom" },
                      attrs: { href: "#tab-custom" }
                    },
                    [_vm._v(" Aangepast ")]
                  )
                ],
                1
              ),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.activeTab,
                    callback: function($$v) {
                      _vm.activeTab = $$v
                    },
                    expression: "activeTab"
                  }
                },
                [
                  _c(
                    "v-tab-item",
                    { attrs: { lazy: true, value: "tab-calculation" } },
                    [
                      _c(
                        "table",
                        {
                          staticClass:
                            "item__list item__list--table item__list--repairlist"
                        },
                        [
                          _c("tr", { staticClass: "label" }, [
                            _c(
                              "td",
                              { staticStyle: { width: "25% !important" } },
                              [_vm._v("Categorie")]
                            ),
                            _c(
                              "td",
                              { staticStyle: { width: "25% !important" } },
                              [_vm._v("Element cluster")]
                            ),
                            _c("td", [_vm._v("Reparaties")])
                          ]),
                          _c("tr", { staticClass: "px-3 border" }, [
                            _c(
                              "td",
                              {
                                staticClass: "border--right",
                                attrs: { valign: "top" }
                              },
                              [
                                _c(
                                  "ul",
                                  { staticClass: "repair__list" },
                                  _vm._l(_vm.repairCategories, function(
                                    category,
                                    index
                                  ) {
                                    return _c(
                                      "li",
                                      {
                                        key: "category-" + index,
                                        staticClass: "repair__item link",
                                        class: {
                                          active:
                                            _vm.newRepair.category === category
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.onClickSetCategory(
                                              category
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" " + _vm._s(category) + " ")]
                                    )
                                  }),
                                  0
                                )
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "border--right",
                                attrs: { valign: "top" }
                              },
                              [
                                _c(
                                  "ul",
                                  { staticClass: "repair__list" },
                                  _vm._l(_vm.repairElementClusters, function(
                                    elementCluster,
                                    index
                                  ) {
                                    return _c(
                                      "li",
                                      {
                                        key: "category-" + index,
                                        staticClass: "repair__item link",
                                        class: {
                                          active:
                                            _vm.newRepair.cluster ===
                                            elementCluster
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.onClickSetElementCluster(
                                              elementCluster
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(elementCluster) + " "
                                        )
                                      ]
                                    )
                                  }),
                                  0
                                )
                              ]
                            ),
                            _c("td", { attrs: { valign: "top" } }, [
                              _c(
                                "ul",
                                { staticClass: "repair__list" },
                                _vm._l(_vm.repairs, function(repair, index) {
                                  return _c(
                                    "li",
                                    {
                                      key: "category-" + index,
                                      staticClass: "repair__item link",
                                      on: {
                                        click: function($event) {
                                          return _vm.onClickCreate(repair)
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", { staticClass: "mr-2" }, [
                                        _vm._v("add")
                                      ]),
                                      _c("span", [_vm._v(_vm._s(repair.name))])
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            ])
                          ])
                        ]
                      )
                    ]
                  ),
                  _c(
                    "v-tab-item",
                    { attrs: { lazy: true, value: "tab-custom" } },
                    [
                      _c(
                        "v-layout",
                        {
                          attrs: { wrap: "", "pa-3": "", "align-baseline": "" }
                        },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Beschrijving van de reparatie",
                                  type: "text"
                                },
                                model: {
                                  value: _vm.newRepair.description,
                                  callback: function($$v) {
                                    _vm.$set(_vm.newRepair, "description", $$v)
                                  },
                                  expression: "newRepair.description"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs8: "", sm4: "", "pr-1": "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Hoeveelheid",
                                  type: "number",
                                  min: "0"
                                },
                                model: {
                                  value: _vm.newRepair.quantity,
                                  callback: function($$v) {
                                    _vm.$set(_vm.newRepair, "quantity", $$v)
                                  },
                                  expression: "newRepair.quantity"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs2: "", "px-1": "" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.unitTypeOptions,
                                  label: "Eenheid"
                                },
                                model: {
                                  value: _vm.newRepair.custom_unit,
                                  callback: function($$v) {
                                    _vm.$set(_vm.newRepair, "custom_unit", $$v)
                                  },
                                  expression: "newRepair.custom_unit"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs2: "", "px-1": "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Prijs per eenheid",
                                  type: "number"
                                },
                                model: {
                                  value: _vm.newRepair.custom_unitprice,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.newRepair,
                                      "custom_unitprice",
                                      $$v
                                    )
                                  },
                                  expression: "newRepair.custom_unitprice"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            {
                              staticClass: "text-xs-center",
                              attrs: { xs6: "", sm2: "", "pa-2": "" }
                            },
                            [
                              _vm._v(" Totaal: "),
                              _c("span", { staticClass: "amount" }, [
                                _vm._v(
                                  _vm._s(_vm._f("currency")(_vm.customTotal))
                                )
                              ])
                            ]
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs6: "", sm2: "", "px-1": "" } },
                            [
                              _c("v-select", {
                                attrs: { items: _vm.vatOptions, label: "BTW" },
                                model: {
                                  value: _vm.newRepair.custom_vat,
                                  callback: function($$v) {
                                    _vm.$set(_vm.newRepair, "custom_vat", $$v)
                                  },
                                  expression: "newRepair.custom_vat"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs6: "" } },
                            [
                              _c("v-checkbox", {
                                attrs: {
                                  label: "Toeslag percentages toepassen",
                                  "hide-details": "",
                                  name: "useAllowance",
                                  color: "#a09b1b"
                                },
                                model: {
                                  value: _vm.newRepair.custom_apply_surtax,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.newRepair,
                                      "custom_apply_surtax",
                                      $$v
                                    )
                                  },
                                  expression: "newRepair.custom_apply_surtax"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs6: "", "text-xs-right": "" } },
                            [
                              _c(
                                "MiButton",
                                {
                                  attrs: {
                                    icon: "add",
                                    compact: "true",
                                    color: "secondary"
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.onClickCreate()
                                    }
                                  }
                                },
                                [_vm._v(" Toevoegen ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }