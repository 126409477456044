import { Component, Vue, Prop } from 'vue-property-decorator';
import { debounce } from 'lodash';
import { Panel, DamageVisibility } from '@/components/damage-panel/DamagePanel';
import { Report } from '@/models/Report';
import ErrorHandler from '@/support/ErrorHandler';
import { AxiosError } from 'axios';
import { Damage } from '@/models/Damage';
import { Rpc } from '@/models/Rpc';

@Component<ImportDamagesDialog>({})
export default class ImportDamagesDialog extends Vue {
  @Prop()
  protected value!: boolean;

  @Prop()
  protected report!: Report;

  protected withPictures = false;

  protected withRepairs = false;

  protected damages: Damage[] = [];

  protected isImportingDamages = false;

  protected isLoadingDamages = false;

  protected isLoadingReports = false;

  protected isLoading = false;

  protected selectedReport: Report | null = null;

  protected reports: Report[] = [];

  protected searchInput = '';

  protected debouncedSearch: Function = debounce(this.getReports, 300);

  protected hasDamagePanelsOpen = false;

  protected damagepanels: Panel[] | null = [];

  protected damageVisibility: DamageVisibility = {
    identicals: true,
    media: true,
    repairs: true,
    ambtshalve: true,
    checkboxes: true,
    openAllPanels: false,
  };

  protected selectedDamages: string[] | null = null;

  protected selectedReportDamages: Damage[] | null = null;

  protected async mounted() {

  }

  protected async getReports() {
    this.isLoadingReports = true;

    this.reports = await new Report()
      .filter('search', this.searchInput)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    this.isLoadingReports = false;
  }

  protected async loadDamages() {
    if (! this.selectedReport) { return; }
    this.isLoadingDamages = true;
    this.selectedReportDamages = await new Damage()
      .refactor()
      .filter({
        report: this.selectedReport,
      })
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
    this.isLoadingDamages = false;
  }

  protected setSelectedDamages(damages: string[]) {
    this.selectedDamages = damages;
  }

  protected importDamages() {
    if (! this.selectedReport) { return; }

    const payload = {
      signature: 'report:copy-damages',
      body: {
        target: this.report.uuid,
        with_repairs: this.withRepairs,
        with_media: this.withPictures,
        damages: this.selectedDamages,
      },
    };

    this.isLoading = true;

    new Rpc()
      .rpcPost(payload)
      .then(() => {
        this.isLoading = false;
        this.close();
        this.$emit('damagesImported');
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected close() {
    this.$emit('input', false);
  }

  protected openAllDamage() {
    this.damagepanels = [...Array(this.damages.length).keys()].map((_) => [true]);
    this.hasDamagePanelsOpen = true;
  }

  protected closeAllDamage() {
    this.damagepanels = [];
    this.hasDamagePanelsOpen = false;
  }
}
