var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "damageRemarks__field" }, [
    _c("div", { staticClass: "damageRemarks__field-label-wrapper" }, [
      _c("span", { staticClass: "damageRemarks__field-label" }, [
        _vm._v(_vm._s(_vm.label))
      ])
    ]),
    _c(
      "div",
      { staticClass: "damageRemarks__field-content" },
      [_vm._t("default")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }