import { cloneDeep } from 'lodash';
import ErrorHandler from '@/support/ErrorHandler';
import { AxiosError } from 'axios';
import { Damage, DamageAnswers } from '@/models/Damage';
import { Repair } from '@/models/Repair';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { RepairType } from '@/models/RepairType';

@Component<AddRepair>({})
export default class AddRepair extends Vue {
  // #region @Props
  /**
  * ie.
  * @Prop()
  * protected user!: User
  */

  @Prop()
  protected types!: RepairType[];

  @Prop()
  protected damage!: Damage;
  // #endregion

  // #region @Refs
  /**
  * ie.
  * @Ref()
  * readonly anotherComponent!: AnotherComponent
  */
  // #endregion

  // #region Class properties
  /**
  * ie.
  * protected isLoading = true;
  * * protected company: Company | null = null;
  */
  protected activeTab = 'tab-calculation';

  protected newRepair: NewRepair = {
    custom_apply_surtax: true,
  };

  protected selectedRepairType: RepairType | null = null;

  protected unitTypeOptions: string[] = ['dag', 'dgdl', 'm1', 'm2', 'pst', 'st'];

  protected vatOptions: number[] = [21, 9, 6, 0];
  // #endregion

  // #region Lifecycle Hooks / Init
  /**
  * ie.
  * protected mounted(): void {
  *  console.log('MyComponent has Mounted');
  * }
  */
  // #endregion

  // #region Class methods

  protected onClickSetCategory(category: string) {
    this.$set(this.newRepair, 'category', category);
  }

  protected onClickSetElementCluster(elementCluster: string) {
    this.$set(this.newRepair, 'cluster', elementCluster);
  }
  // #endregion

  // #region Async methods
  /**
  * ie.
  * protected async fetchUserCompany(): Promise<void> {
  *  this.company = await new Company().filter({user: this.user.id}).all();
  * }
  */
  protected async onClickCreate(repairType?: RepairType): Promise<void> {
    this.selectedRepairType = repairType || null;
    const repair = await new Repair()
      .create(this.payload)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    if (repair) {
      repair.is_new = true;
      this.$emit('created', repair);
    }
  }
  // #endregion

  // #region Getters & Setters
  /**
  * ie.
  * protected get companyName(): string {
  *  return this?.company.name || 'N/A';
  * }
  */

  protected get customTotal() {
    return (Number(this.newRepair?.quantity || 0) * Number(this.newRepair?.custom_unitprice || 0));
  }

  protected get hasCategory() {
    return !! this.newRepair.category;
  }

  protected get hasElementCluster() {
    return !! this.newRepair.cluster;
  }

  protected get payload(): NewRepairPayload {
    if (this.activeTab === 'tab-custom') {
      return {
        quantity: this.newRepair.quantity,
        damage: this.damage.uuid,
        form: 'custom',
        custom_name: this.newRepair.description,
        custom_unit: this.newRepair.custom_unit,
        custom_vat: this.newRepair.custom_vat,
        custom_unitprice: this.newRepair.custom_unitprice,
        custom_apply_surtax: this.newRepair.custom_apply_surtax || false,
      };
    }
    return {
      quantity: this.selectedRepairType?.default_quantity || '1',
      damage: this.damage.uuid,
      form: 'calculation',
      type: this.selectedRepairType?.uuid || '',
    };
  }

  protected get section(): string {
    if (this.damage && this.damage.answers) {
      if ((this.damage.answers as DamageAnswers).LocatieSchade === 'Buitenkant gebouw') {
        return 'exterior';
      } if ((this.damage.answers as DamageAnswers).LocatieSchade === 'Binnenkant gebouw') {
        return 'interior';
      }
      if ((this.damage.answers as DamageAnswers).Locatie === 'Buitenkant gebouw') {
        return 'exterior';
      } if ((this.damage.answers as DamageAnswers).Locatie === 'Binnenkant gebouw') {
        return 'interior';
      }
    }

    return '';
  }

  protected get repairTypes(): RepairType[] {
    const types = cloneDeep(this.types).filter((repairType: RepairType) => (this.section ? (repairType.section === this.section) : true));

    return types;
  }

  protected get repairCategories(): string[] {
    const categories: string[] = [];

    this.repairTypes.forEach((type: RepairType) => {
      if (type.subsection && ! categories.includes(type.subsection)) {
        categories.push(type.subsection);
      }
    });

    return categories;
  }

  protected get repairElementClusters(): string[] {
    const categories: string[] = [];

    let types = cloneDeep(this.repairTypes);
    types = types.filter((repairType: RepairType) => this.newRepair.category === repairType.subsection);

    types.forEach((type: RepairType) => {
      if (type.elementcluster && ! categories.includes(type.elementcluster)) {
        categories.push(type.elementcluster);
      }
    });

    return categories;
  }

  protected get repairs(): RepairType[] {
    return this.repairTypes.filter((repairType: RepairType) => (this.newRepair.category === repairType.subsection) && (this.newRepair.cluster === repairType.elementcluster));
  }
  // #endregion

  // #region @Watchers
  /**
  * ie.
  * @Watch('company')
  * protected companyChanged() {
  *  console.log('Company Changed') ;
  * }
  */
  // #endregion
}

interface NewRepair {
  description?: string;
  quantity?: string;
  location?: string;
  damage?: Damage;
  form?: string;
  custom_name?: string;
  custom_unit?: string;
  custom_vat?: string;
  custom_unitprice?: string;
  custom_apply_surtax?: boolean;
  cluster?: string;
  category?: string;
}

interface NewRepairPayload {
  type?: string;
  description?: string;
  quantity?: string;
  location?: string;
  damage?: string;
  form?: string;
  custom_name?: string;
  custom_unit?: string;
  custom_vat?: string;
  custom_unitprice?: string;
  custom_apply_surtax?: boolean;
}
