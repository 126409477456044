var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fill-height expert-container" },
    [
      _vm.isAllowedToView
        ? [
            _c(
              "SideDrawer",
              {
                model: {
                  value: _vm.showDrawer,
                  callback: function($$v) {
                    _vm.showDrawer = $$v
                  },
                  expression: "showDrawer"
                }
              },
              [
                !_vm.isLoadingArticle && _vm.$store.state.isBETA
                  ? _c(
                      "template",
                      { slot: "title" },
                      [
                        _vm.selectedArticle
                          ? _c(
                              "router-link",
                              {
                                attrs: {
                                  to:
                                    "/schadebibliotheek/" +
                                    _vm.selectedArticle.id,
                                  target: "_blank"
                                }
                              },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  { staticClass: "link" },
                                                  on
                                                ),
                                                [_vm._v("exit_to_app")]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      676315320
                                    )
                                  },
                                  [_c("span", [_vm._v("Ga naar artikel")])]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "template",
                  { slot: "content" },
                  [
                    _vm.isLoadingArticle
                      ? _c("LoaderCard", {
                          staticClass: "pa-2",
                          attrs: { flat: "", type: "spinner--center" }
                        })
                      : _vm._e(),
                    !_vm.isLoadingArticle && _vm.selectedArticle
                      ? _c("LibraryArticle", {
                          attrs: { article: _vm.selectedArticle }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ],
              2
            ),
            _c(
              "v-layout",
              { attrs: { row: "" } },
              [
                _vm.hasRejectedInfo() ||
                (_vm.report.internal_reject_comment &&
                  _vm.report.internal_reject_comment.length)
                  ? _c(
                      "v-flex",
                      {
                        staticClass: "damageStatusContainer rejected",
                        attrs: {
                          xs12: "",
                          id: "report-status-comment-container"
                        }
                      },
                      [
                        _c(
                          "v-layout",
                          { attrs: { wrap: "" } },
                          [
                            _vm.report.reject_reasons &&
                            _vm.report.reject_reasons.length
                              ? _c("v-flex", { attrs: { xs12: "" } }, [
                                  _c(
                                    "div",
                                    { staticClass: "damageStatusInfo" },
                                    [
                                      _c("span", [_vm._v("Afkeurreden(en):")]),
                                      _c(
                                        "ul",
                                        _vm._l(
                                          _vm.report.reject_reasons,
                                          function(rejectReason, index) {
                                            return _c("li", { key: index }, [
                                              _vm._v(_vm._s(rejectReason.name))
                                            ])
                                          }
                                        ),
                                        0
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm.report.internal_reject_comment &&
                            _vm.report.internal_reject_comment.length
                              ? _c("v-flex", { attrs: { xs12: "" } }, [
                                  _c(
                                    "div",
                                    { staticClass: "damageStatusInfo" },
                                    [
                                      _c("span", [
                                        _vm._v("Toelichting intern:")
                                      ]),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.report.internal_reject_comment
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm.report.reject_comment &&
                            _vm.report.reject_comment.length
                              ? _c("v-flex", { attrs: { xs12: "" } }, [
                                  _c(
                                    "div",
                                    { staticClass: "damageStatusInfo" },
                                    [
                                      _c("span", [_vm._v("Toelichting:")]),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.report.reject_comment) +
                                          " "
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            _c(
              "v-tabs",
              {
                attrs: { "slider-color": "secondary" },
                model: {
                  value: _vm.activeTab,
                  callback: function($$v) {
                    _vm.activeTab = $$v
                  },
                  expression: "activeTab"
                }
              },
              [
                _vm._l(_vm.tabs, function(tab) {
                  return [
                    tab.visible()
                      ? _c(
                          "v-tab",
                          {
                            key: tab.key,
                            staticClass: "tab__filled",
                            class: { active: _vm.activeTab === tab.key },
                            attrs: { href: tab.href }
                          },
                          [_vm._v(" " + _vm._s(tab.name) + " ")]
                        )
                      : _vm._e()
                  ]
                }),
                _c(
                  "MiButton",
                  {
                    staticClass: "mr-2 previewPdf",
                    attrs: {
                      disabled: !_vm.report.links.preview,
                      color: "primary",
                      compact: "true",
                      icon: "picture_as_pdf",
                      outline: "true",
                      fontSize: "10",
                      round: "true"
                    },
                    nativeOn: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.previewPdf($event)
                      }
                    }
                  },
                  [_vm._v(" Preview schade rapport ")]
                )
              ],
              2
            ),
            _c(
              "v-tabs-items",
              {
                staticClass: "expert-tab-container",
                style:
                  "height: calc(100% - " +
                  _vm.expertTabContainerHeight +
                  "px);",
                model: {
                  value: _vm.activeTab,
                  callback: function($$v) {
                    _vm.activeTab = $$v
                  },
                  expression: "activeTab"
                }
              },
              [
                _c(
                  "v-tab-item",
                  {
                    staticClass: "fill-height",
                    attrs: {
                      lazy: true,
                      value: "tab-questionair",
                      transition: false,
                      "reverse-transition": false
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "overflow-auto elementPanel fill-height",
                        attrs: { id: "element-panel-report-questionnaire" }
                      },
                      [
                        _vm.isLoading
                          ? _c("LoaderCard", {
                              attrs: { flat: "", type: "spinner--center" }
                            })
                          : _vm._e(),
                        !_vm.isLoading
                          ? [
                              _vm.report.isRegulier2021Report ||
                              _vm.report.isVesNulmetingReport ||
                              _vm.report.isUsingRegulier2021Questions
                                ? [
                                    _vm.report
                                      ? _c(
                                          "div",
                                          { staticClass: "questionStepper" },
                                          [
                                            _c(
                                              "v-stepper",
                                              {
                                                model: {
                                                  value: _vm.newCurrentStep,
                                                  callback: function($$v) {
                                                    _vm.newCurrentStep = $$v
                                                  },
                                                  expression: "newCurrentStep"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-stepper-header",
                                                  [
                                                    _vm._l(
                                                      _vm.newSections,
                                                      function(section) {
                                                        return [
                                                          [
                                                            _c(
                                                              "v-stepper-step",
                                                              {
                                                                key:
                                                                  section.step +
                                                                  "-step",
                                                                attrs: {
                                                                  color:
                                                                    "secondary",
                                                                  complete:
                                                                    _vm.newCurrentStep >
                                                                    section.step,
                                                                  step:
                                                                    section.step,
                                                                  editable: "",
                                                                  "edit-icon":
                                                                    "check"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      section.step
                                                                    ) +
                                                                    ". " +
                                                                    _vm._s(
                                                                      section.name
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            section.step !==
                                                            _vm.newSections
                                                              .length
                                                              ? _c(
                                                                  "v-divider",
                                                                  {
                                                                    key:
                                                                      section.step
                                                                  }
                                                                )
                                                              : _vm._e()
                                                          ]
                                                        ]
                                                      }
                                                    )
                                                  ],
                                                  2
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                : [
                                    _vm.report
                                      ? _c(
                                          "div",
                                          { staticClass: "questionStepper" },
                                          [
                                            _c(
                                              "v-stepper",
                                              {
                                                model: {
                                                  value: _vm.currentStep,
                                                  callback: function($$v) {
                                                    _vm.currentStep = $$v
                                                  },
                                                  expression: "currentStep"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-stepper-header",
                                                  [
                                                    _vm._l(
                                                      _vm.sections.length,
                                                      function(section) {
                                                        return [
                                                          _vm.showSteps(section)
                                                            ? [
                                                                _c(
                                                                  "v-stepper-step",
                                                                  {
                                                                    key:
                                                                      section +
                                                                      "-step",
                                                                    attrs: {
                                                                      color:
                                                                        "secondary",
                                                                      complete:
                                                                        _vm.currentStep >
                                                                        section,
                                                                      step: section,
                                                                      editable:
                                                                        "",
                                                                      "edit-icon":
                                                                        "check"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Stap " +
                                                                        _vm._s(
                                                                          section
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ]
                                                                ),
                                                                section !== 8
                                                                  ? _c(
                                                                      "v-divider",
                                                                      {
                                                                        key: section
                                                                      }
                                                                    )
                                                                  : _vm._e()
                                                              ]
                                                            : _vm._e()
                                                        ]
                                                      }
                                                    )
                                                  ],
                                                  2
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                              _vm.report.validations
                                ? _c(
                                    "v-layout",
                                    {
                                      staticClass:
                                        "pl-0 item__list item__list--reports",
                                      attrs: { wrap: "" }
                                    },
                                    [
                                      _vm._l(_vm.report.validations, function(
                                        validation,
                                        key
                                      ) {
                                        return [
                                          validation !== "ok"
                                            ? _c(
                                                "v-flex",
                                                {
                                                  key: key,
                                                  staticClass: "list__item",
                                                  attrs: { xs12: "" }
                                                },
                                                [
                                                  _c("ValidationWarning", {
                                                    attrs: {
                                                      validation: validation,
                                                      validationKey: key
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]
                                      })
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm.isLoadingQuestions
                                ? _c("LoaderCard", {
                                    attrs: { flat: "", type: "spinner--center" }
                                  })
                                : _vm._e(),
                              _vm.currentStep === 3
                                ? [
                                    _c(
                                      "v-layout",
                                      [
                                        _c(
                                          "v-flex",
                                          { attrs: { "pa-3": "" } },
                                          [
                                            _c("HistoricalDamagePanel", {
                                              attrs: {
                                                reportId: _vm.report.uuid
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                : [
                                    !_vm.isLoadingQuestions &&
                                    _vm.reportQuestions &&
                                    _vm.reportAnswers
                                      ? _c("Questionnaire", {
                                          attrs: {
                                            report: _vm.report,
                                            questions: _vm.reportQuestions,
                                            answers: _vm.reportAnswers,
                                            disabled: _vm.isLockedByUser,
                                            enableReadMode:
                                              _vm.$store.state.Auth
                                                .has_quick_questions
                                          },
                                          on: {
                                            reloadDamageAnswers:
                                              _vm.reloadDamageAnswers,
                                            reloadReportAnswers:
                                              _vm.reloadReportAnswers,
                                            reportAnswerSaved:
                                              _vm.reportAnswerSaved,
                                            onClickInfo: _vm.onClickInfo
                                          }
                                        })
                                      : _vm._e()
                                  ],
                              _c(
                                "v-layout",
                                { attrs: { "mt-3": "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs6: "" } },
                                    [
                                      (_vm.report.isRegulier2021Report ||
                                      _vm.report.isVesNulmetingReport
                                      ? _vm.newCurrentStep > 1
                                      : _vm.currentStep > 1)
                                        ? _c(
                                            "MiButton",
                                            {
                                              attrs: {
                                                color: "secondary",
                                                outline: "true",
                                                icon: "keyboard_arrow_left"
                                              },
                                              nativeOn: {
                                                click: function($event) {
                                                  return _vm.changeStep(
                                                    "previous"
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v(" Vorige stap ")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "text-right",
                                      attrs: { xs6: "" }
                                    },
                                    [
                                      (_vm.report.isRegulier2021Report ||
                                      _vm.report.isVesNulmetingReport
                                      ? _vm.newCurrentStep <
                                        _vm.newSections.length
                                      : _vm.currentStep < _vm.sections.length)
                                        ? _c(
                                            "MiButton",
                                            {
                                              attrs: {
                                                color: "secondary",
                                                icon: "navigate_next"
                                              },
                                              nativeOn: {
                                                click: function($event) {
                                                  return _vm.changeStep("next")
                                                }
                                              }
                                            },
                                            [_vm._v(" Volgende stap ")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  ]
                ),
                _c(
                  "v-tab-item",
                  {
                    staticClass: "fill-height",
                    attrs: {
                      lazy: true,
                      value: "tab-schades",
                      transition: false,
                      "reverse-transition": false
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "overflow-auto elementPanel fill-height pa-0"
                      },
                      [
                        _c(
                          "v-layout",
                          { staticClass: "damageContainer" },
                          [
                            _c(
                              "v-flex",
                              {
                                staticClass: "damageContainer__list",
                                attrs: { xs3: "" }
                              },
                              [
                                _c(
                                  "v-layout",
                                  {
                                    staticClass: "align-content-between",
                                    attrs: { wrap: "", "fill-height": "" }
                                  },
                                  [
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "list__damageContainer",
                                        attrs: { xs12: "" }
                                      },
                                      [
                                        !_vm.isLoadingDamages &&
                                        _vm.damages &&
                                        _vm.damages.length === 0
                                          ? _c(
                                              "v-layout",
                                              { attrs: { "pa-3": "" } },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "Geen schade beschikbaar"
                                                  )
                                                ])
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.isLoadingDamages
                                          ? _c("LoaderCard", {
                                              staticClass: "mt-4",
                                              attrs: {
                                                flat: "",
                                                type: "spinner--center"
                                              }
                                            })
                                          : _vm._e(),
                                        _vm.damages &&
                                        _vm.damages.length &&
                                        !_vm.isLoadingDamages
                                          ? _c(
                                              "v-layout",
                                              {
                                                staticClass: "item__list",
                                                attrs: { wrap: "" }
                                              },
                                              [
                                                _c(
                                                  "draggable",
                                                  _vm._b(
                                                    {
                                                      staticClass: "list-group",
                                                      attrs: {
                                                        options: { delay: 500 },
                                                        disabled:
                                                          _vm.isLockedByUser
                                                      },
                                                      on: {
                                                        start: function(
                                                          $event
                                                        ) {
                                                          _vm.drag = true
                                                        },
                                                        end: function($event) {
                                                          ;(_vm.drag = false),
                                                            _vm.updateDamageOrder()
                                                        }
                                                      },
                                                      model: {
                                                        value: _vm.damages,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.damages = $$v
                                                        },
                                                        expression: "damages"
                                                      }
                                                    },
                                                    "draggable",
                                                    _vm.dragOptions,
                                                    false
                                                  ),
                                                  [
                                                    _c(
                                                      "transition-group",
                                                      _vm._l(
                                                        _vm.damages,
                                                        function(
                                                          damage,
                                                          index
                                                        ) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: damage.uuid,
                                                              staticClass:
                                                                "list__item",
                                                              class: [
                                                                {
                                                                  active:
                                                                    (_vm.currentDamage
                                                                      ? _vm
                                                                          .currentDamage
                                                                          .uuid
                                                                      : "") ===
                                                                    damage.uuid
                                                                },
                                                                {
                                                                  accepted:
                                                                    damage.status ===
                                                                    "accepted"
                                                                },
                                                                {
                                                                  rejected:
                                                                    damage.status ===
                                                                    "rejected"
                                                                },
                                                                {
                                                                  changed:
                                                                    damage.status ===
                                                                    "changed"
                                                                }
                                                              ]
                                                            },
                                                            [
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    xs12: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "sortArrows"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          staticClass:
                                                                            "sortIcon"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "drag_indicator"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-layout",
                                                                    {
                                                                      staticClass:
                                                                        "damage",
                                                                      attrs: {
                                                                        wrap:
                                                                          "",
                                                                        "align-center":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-flex",
                                                                        {
                                                                          staticClass:
                                                                            "damage__description",
                                                                          class: {
                                                                            "tw-text-right":
                                                                              damage.is_room_without_damage
                                                                          },
                                                                          attrs: {
                                                                            xs9:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              _vm.getDamage(
                                                                                damage
                                                                              )
                                                                              _vm.addCurrentDamageToStorage(
                                                                                damage
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              class: {
                                                                                "tw-italic tw-text-gray-400":
                                                                                  damage.is_room_without_damage
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.createDamageName(
                                                                                      damage,
                                                                                      index
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "float-right"
                                                                            },
                                                                            [
                                                                              _vm.showHeaderWarning(
                                                                                damage.validations
                                                                              )
                                                                                ? _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "customRepairIndicator"
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        {
                                                                                          staticClass:
                                                                                            "custom"
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "warning"
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _vm._e(),
                                                                              _vm.showHeaderError(
                                                                                damage.validations
                                                                              )
                                                                                ? _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "customRepairIndicator customRepairIndicator--error"
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        {
                                                                                          staticClass:
                                                                                            "custom"
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "warning"
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _vm._e()
                                                                            ]
                                                                          )
                                                                        ]
                                                                      ),
                                                                      !_vm.isLockedByUser &&
                                                                      !damage.is_room_without_damage
                                                                        ? _c(
                                                                            "v-flex",
                                                                            {
                                                                              staticClass:
                                                                                "text-xs-right damage__icon",
                                                                              attrs: {
                                                                                xs1:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.onClickDuplicateDamage(
                                                                                        damage
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "file_copy"
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                      !_vm.isLockedByUser
                                                                        ? _c(
                                                                            "v-flex",
                                                                            {
                                                                              staticClass:
                                                                                "text-xs-right damage__icon damage__icon--delete",
                                                                              attrs: {
                                                                                xs1:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.onClickDeleteDamage(
                                                                                        damage
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "delete"
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                      _c(
                                                                        "v-flex",
                                                                        {
                                                                          staticClass:
                                                                            "text-xs-right",
                                                                          attrs: {
                                                                            xs1:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          damage.internal_status &&
                                                                          damage.internal_status !==
                                                                            "pending"
                                                                            ? _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "tc__icon",
                                                                                  class:
                                                                                    damage.internal_status
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " IA "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            : _vm._e()
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    ),
                                    !_vm.isLoadingDamages
                                      ? _c("v-flex", { attrs: { xs12: "" } }, [
                                          _c(
                                            "div",
                                            { staticClass: "addDamage" },
                                            [
                                              _c(
                                                "MiButton",
                                                {
                                                  attrs: {
                                                    disabled:
                                                      _vm.isLockedByUser,
                                                    color: "secondary",
                                                    fullWidth: true,
                                                    icon: "add_circle"
                                                  },
                                                  nativeOn: {
                                                    click: function($event) {
                                                      return _vm.onClickAddDamage(
                                                        $event
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v(" Voeg schade toe ")]
                                              )
                                            ],
                                            1
                                          )
                                        ])
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              { attrs: { xs12: "" } },
                              [
                                _c(
                                  "v-layout",
                                  {
                                    attrs: {
                                      wrap: "",
                                      id: "damage-status-comment-container"
                                    }
                                  },
                                  [
                                    _vm.report &&
                                    _vm.currentDamage &&
                                    (_vm.currentDamage.reject_reasons.length ||
                                      _vm.currentDamage.reject_comment.length)
                                      ? _c(
                                          "v-flex",
                                          {
                                            staticClass:
                                              "damageStatusContainer",
                                            class: [
                                              _vm.currentDamage.status,
                                              _vm.currentDamage
                                                .internal_reject_comment.length
                                                ? "xs6"
                                                : "xs12"
                                            ]
                                          },
                                          [
                                            _vm.report.status ===
                                            _vm.ReportStatus
                                              .STRUCTURAL_ENGINEER_REJECTED
                                              ? [
                                                  _c(
                                                    "h3",
                                                    {
                                                      staticClass:
                                                        "damageStatusText"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Bouwkundige vraag"
                                                      )
                                                    ]
                                                  )
                                                ]
                                              : [
                                                  _vm.currentDamage.status ===
                                                  "accepted"
                                                    ? _c(
                                                        "h3",
                                                        {
                                                          staticClass:
                                                            "damageStatusText"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Schade goedgekeurd door schadebepaling "
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm.currentDamage.status ===
                                                  "changed"
                                                    ? _c(
                                                        "h3",
                                                        {
                                                          staticClass:
                                                            "damageStatusText"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Schade aangepast door schadebepaling "
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm.currentDamage.status ===
                                                  "rejected"
                                                    ? _c(
                                                        "h3",
                                                        {
                                                          staticClass:
                                                            "damageStatusText"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Schade afgekeurd door schadebepaling "
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                            _vm.currentDamage.reject_reasons &&
                                            _vm.currentDamage.reject_reasons
                                              .length
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "damageStatusInfo"
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v("Afkeurreden(en):")
                                                    ]),
                                                    _c(
                                                      "ul",
                                                      _vm._l(
                                                        _vm.currentDamage
                                                          .reject_reasons,
                                                        function(
                                                          reason,
                                                          index
                                                        ) {
                                                          return _c(
                                                            "li",
                                                            { key: index },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  reason.name
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.currentDamage.reject_comment &&
                                            _vm.currentDamage.reject_comment
                                              .length
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "damageStatusInfo"
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v("Toelichting:")
                                                    ]),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.currentDamage
                                                            .reject_comment
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm.report &&
                                    _vm.currentDamage &&
                                    _vm.currentDamage.internal_reject_comment
                                      .length
                                      ? _c(
                                          "v-flex",
                                          {
                                            staticClass:
                                              "damageStatusContainer",
                                            class: [
                                              _vm.currentDamage.internal_status,
                                              _vm.currentDamage.reject_reasons
                                                .length ||
                                              _vm.currentDamage.reject_comment
                                                .length
                                                ? "xs6"
                                                : "xs12"
                                            ]
                                          },
                                          [
                                            _vm.currentDamage
                                              .internal_status === "accepted"
                                              ? _c(
                                                  "h3",
                                                  {
                                                    staticClass:
                                                      "damageStatusText"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Intern Schade goedgekeurd "
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.currentDamage
                                              .internal_status === "changed"
                                              ? _c(
                                                  "h3",
                                                  {
                                                    staticClass:
                                                      "damageStatusText"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Intern Schade aangepast "
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.currentDamage
                                              .internal_status === "rejected"
                                              ? _c(
                                                  "h3",
                                                  {
                                                    staticClass:
                                                      "damageStatusText"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Intern Schade afgekeurd "
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.currentDamage
                                              .internal_reject_comment &&
                                            _vm.currentDamage
                                              .internal_reject_comment.length
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "damageStatusInfo"
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "Toelichting intern:"
                                                      )
                                                    ]),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.currentDamage
                                                            .internal_reject_comment
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _vm.currentDamage
                                  ? _c(
                                      "v-tabs",
                                      {
                                        attrs: { "slider-color": "secondary" },
                                        model: {
                                          value: _vm.activeTabSub,
                                          callback: function($$v) {
                                            _vm.activeTabSub = $$v
                                          },
                                          expression: "activeTabSub"
                                        }
                                      },
                                      [
                                        _vm._l(_vm.damageTabs, function(tab) {
                                          return [
                                            tab.visible()
                                              ? _c(
                                                  "v-tab",
                                                  {
                                                    key: tab.key,
                                                    staticClass: "tab__filled",
                                                    class: {
                                                      active:
                                                        _vm.activeTab ===
                                                        tab.key
                                                    },
                                                    attrs: { href: tab.href },
                                                    on: {
                                                      click: function($event) {
                                                        tab.click
                                                          ? tab.click()
                                                          : null
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(tab.name) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        }),
                                        _c(
                                          "v-layout",
                                          {
                                            attrs: {
                                              wrap: "",
                                              "align-center": "",
                                              "text-xs-right": ""
                                            }
                                          },
                                          [
                                            _c(
                                              "v-flex",
                                              {
                                                attrs: { xs12: "", "pr-3": "" }
                                              },
                                              [
                                                _c(
                                                  "h2",
                                                  {
                                                    staticClass:
                                                      "elementSubTitle subheading"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Schade " +
                                                        _vm._s(
                                                          _vm.currentDamageIndex
                                                        )
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                !_vm.currentDamage
                                  ? _c(
                                      "v-layout",
                                      {
                                        attrs: {
                                          wrap: "",
                                          "align-center": "",
                                          "justify-center": "",
                                          "fill-height": ""
                                        }
                                      },
                                      [
                                        _vm.isLoadingDamage
                                          ? _c("LoaderCard", {
                                              attrs: {
                                                flat: "",
                                                type: "spinner--center",
                                                minHeight: "300px"
                                              }
                                            })
                                          : _vm._e(),
                                        !_vm.isLoadingDamage
                                          ? _c("NoDataCard", {
                                              attrs: {
                                                icon: "assignment",
                                                text: "Geen schade gekozen"
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.isLoadingDamage
                                  ? _c(
                                      "v-layout",
                                      {
                                        attrs: {
                                          wrap: "",
                                          "align-center": "",
                                          "justify-center": "",
                                          "fill-height": ""
                                        }
                                      },
                                      [
                                        _c("LoaderCard", {
                                          attrs: {
                                            flat: "",
                                            type: "spinner--center",
                                            minHeight: "300px"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.currentDamage && !_vm.isLoadingDamage
                                  ? [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "elementPanel elementPanelExpert",
                                          class: {
                                            "d-none":
                                              _vm.activeTabSub !== "tab-report"
                                          },
                                          style:
                                            "height: calc(100% - " +
                                            _vm.currentDamageContainerHeight +
                                            "px);",
                                          attrs: {
                                            id:
                                              "element-panel-damage-questionnaire"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "damageContainer__content"
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "px-3" },
                                                [
                                                  _vm.currentDamage
                                                    ? [
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _vm.currentDamage &&
                                                            _vm.currentDamage
                                                              .validations
                                                              ? _c(
                                                                  "v-layout",
                                                                  {
                                                                    staticClass:
                                                                      "px-0 mb-3 item__list item__list--reports",
                                                                    class: {
                                                                      "item__list--validations":
                                                                        _vm.hasValidations
                                                                    },
                                                                    attrs: {
                                                                      wrap: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._l(
                                                                      _vm
                                                                        .currentDamage
                                                                        .validations,
                                                                      function(
                                                                        validation,
                                                                        key
                                                                      ) {
                                                                        return [
                                                                          validation !==
                                                                          "ok"
                                                                            ? _c(
                                                                                "v-flex",
                                                                                {
                                                                                  key: key,
                                                                                  staticClass:
                                                                                    "list__item",
                                                                                  attrs: {
                                                                                    xs12:
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "ValidationWarning",
                                                                                    {
                                                                                      attrs: {
                                                                                        validation: validation,
                                                                                        validationKey: key
                                                                                      }
                                                                                    }
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _vm._e()
                                                                        ]
                                                                      }
                                                                    )
                                                                  ],
                                                                  2
                                                                )
                                                              : _vm._e()
                                                          ],
                                                          1
                                                        ),
                                                        _vm.damageQuestions &&
                                                        _vm.currentDamage &&
                                                        !_vm.isLoading
                                                          ? _c(
                                                              "Questionnaire",
                                                              {
                                                                attrs: {
                                                                  questions:
                                                                    _vm.damageQuestions,
                                                                  answers:
                                                                    _vm
                                                                      .currentDamage
                                                                      .answers,
                                                                  report:
                                                                    _vm.report,
                                                                  damage:
                                                                    _vm.currentDamage,
                                                                  disabled:
                                                                    _vm.isLockedByUser,
                                                                  enableReadMode:
                                                                    _vm.$store
                                                                      .state
                                                                      .Auth
                                                                      .has_quick_questions
                                                                },
                                                                on: {
                                                                  damageAnswerSaved:
                                                                    _vm.damageAnswerSaved,
                                                                  reloadAnswers:
                                                                    _vm.reloadDamageAnswers,
                                                                  reloadDamageAnswers:
                                                                    _vm.reloadDamageAnswers,
                                                                  reloadReportAnswers:
                                                                    _vm.reloadReportAnswers,
                                                                  onClickInfo:
                                                                    _vm.onClickInfo
                                                                }
                                                              }
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    : _vm._e()
                                                ],
                                                2
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "elementPanel elementPanelExpert",
                                          class: {
                                            "d-none":
                                              _vm.activeTabSub !== "tab-remarks"
                                          },
                                          style:
                                            "height: calc(100% - " +
                                            _vm.currentDamageContainerHeight +
                                            "px);"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "damageContainer__content"
                                            },
                                            [
                                              _c("DamageRemarks", {
                                                attrs: {
                                                  damage: _vm.currentDamage,
                                                  disabled: _vm.isLockedByUser
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "elementPanel elementPanelExpert",
                                          class: {
                                            "d-none":
                                              _vm.activeTabSub !== "tab-repairs"
                                          },
                                          style:
                                            "height: calc(100% - " +
                                            _vm.currentDamageContainerHeight +
                                            "px);"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "damageContainer__content"
                                            },
                                            [
                                              _vm.isLoadingRepairs
                                                ? _c("LoaderCard", {
                                                    attrs: {
                                                      flat: "",
                                                      type: "spinner--center",
                                                      minHeight: "300px"
                                                    }
                                                  })
                                                : _vm._e(),
                                              !_vm.isLoadingRepairs &&
                                              _vm.currentDamage
                                                ? [
                                                    _c(
                                                      "v-layout",
                                                      {
                                                        attrs: {
                                                          wrap: "",
                                                          "px-3": ""
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            attrs: { xs6: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  wrap: "",
                                                                  row: ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs12: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-checkbox",
                                                                      {
                                                                        staticClass:
                                                                          "ma-0 pa-0",
                                                                        attrs: {
                                                                          disabled:
                                                                            _vm.isLockedByUser,
                                                                          right:
                                                                            "",
                                                                          label:
                                                                            "Uitgezonderde schade",
                                                                          "hide-details":
                                                                            ""
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm.exceptionVisible,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.exceptionVisible = $$v
                                                                          },
                                                                          expression:
                                                                            "exceptionVisible"
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm.exceptionVisible
                                                                  ? [
                                                                      _c(
                                                                        "v-flex",
                                                                        {
                                                                          attrs: {
                                                                            xs12:
                                                                              "",
                                                                            sm8:
                                                                              "",
                                                                            "pt-2":
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-select",
                                                                            {
                                                                              attrs: {
                                                                                items:
                                                                                  _vm.damageExceptions,
                                                                                "item-value":
                                                                                  "key",
                                                                                "item-text":
                                                                                  "name",
                                                                                label:
                                                                                  "Uitzondering",
                                                                                clearable:
                                                                                  ""
                                                                              },
                                                                              on: {
                                                                                change: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.updateDamageException()
                                                                                }
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm
                                                                                    .currentDamage
                                                                                    .exception_reason,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.currentDamage,
                                                                                    "exception_reason",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "currentDamage.exception_reason"
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm
                                                                        .currentDamage
                                                                        .exception_reason ===
                                                                      "overig"
                                                                        ? _c(
                                                                            "v-flex",
                                                                            {
                                                                              attrs: {
                                                                                xs12:
                                                                                  "",
                                                                                sm8:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-textarea",
                                                                                {
                                                                                  attrs: {
                                                                                    label:
                                                                                      "Toelichting"
                                                                                  },
                                                                                  on: {
                                                                                    change: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.updateDamageException()
                                                                                    }
                                                                                  },
                                                                                  model: {
                                                                                    value:
                                                                                      _vm
                                                                                        .currentDamage
                                                                                        .exception_comment,
                                                                                    callback: function(
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        _vm.currentDamage,
                                                                                        "exception_comment",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                    expression:
                                                                                      "currentDamage.exception_comment"
                                                                                  }
                                                                                }
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e()
                                                                    ]
                                                                  : _vm._e()
                                                              ],
                                                              2
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            attrs: {
                                                              xs6: "",
                                                              "text-xs-right":
                                                                ""
                                                            }
                                                          },
                                                          [
                                                            !_vm.isLockedByUser
                                                              ? [
                                                                  _c(
                                                                    "v-tooltip",
                                                                    {
                                                                      attrs: {
                                                                        bottom:
                                                                          ""
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "activator",
                                                                            fn: function(
                                                                              ref
                                                                            ) {
                                                                              var on =
                                                                                ref.on
                                                                              return [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  _vm._g(
                                                                                    {
                                                                                      staticClass:
                                                                                        "cursor-pointer",
                                                                                      attrs: {
                                                                                        color:
                                                                                          "primary"
                                                                                      },
                                                                                      nativeOn: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.openTransferDialog(
                                                                                            "Reparaties",
                                                                                            "repair"
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _vm._v(
                                                                                      " content_copy "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            }
                                                                          }
                                                                        ],
                                                                        null,
                                                                        false,
                                                                        2948638604
                                                                      )
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Dupliceer naar schade"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-tooltip",
                                                                    {
                                                                      attrs: {
                                                                        bottom:
                                                                          ""
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "activator",
                                                                            fn: function(
                                                                              ref
                                                                            ) {
                                                                              var on =
                                                                                ref.on
                                                                              return [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  _vm._g(
                                                                                    {
                                                                                      staticClass:
                                                                                        "mx-2 cursor-pointer",
                                                                                      attrs: {
                                                                                        color:
                                                                                          "primary"
                                                                                      },
                                                                                      nativeOn: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          _vm.isAddingRepairSet = true
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _vm._v(
                                                                                      " playlist_add "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            }
                                                                          }
                                                                        ],
                                                                        null,
                                                                        false,
                                                                        785264657
                                                                      )
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Reparatieset toevoegen"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-tooltip",
                                                                    {
                                                                      attrs: {
                                                                        bottom:
                                                                          ""
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "activator",
                                                                            fn: function(
                                                                              ref
                                                                            ) {
                                                                              var on =
                                                                                ref.on
                                                                              return [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  _vm._g(
                                                                                    {
                                                                                      staticClass:
                                                                                        "cursor-pointer",
                                                                                      attrs: {
                                                                                        color:
                                                                                          "secondary"
                                                                                      },
                                                                                      nativeOn: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          _vm.isCreatingRepair = true
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _vm._v(
                                                                                      " add "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            }
                                                                          }
                                                                        ],
                                                                        null,
                                                                        false,
                                                                        324541462
                                                                      )
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Reparatie toevoegen"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              : _vm._e()
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c("div", {
                                                      staticClass:
                                                        "my-2 separator"
                                                    })
                                                  ]
                                                : _vm._e(),
                                              !_vm.isLoadingRepairs &&
                                              _vm.currentDamage &&
                                              _vm.currentDamage.repairs &&
                                              _vm.currentDamage.repairs.length >
                                                0
                                                ? [
                                                    _c("div", [
                                                      _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            "v-layout",
                                                            {
                                                              staticClass:
                                                                "item__list",
                                                              attrs: {
                                                                wrap: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  staticClass:
                                                                    "label",
                                                                  attrs: {
                                                                    xs12: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-layout",
                                                                    {
                                                                      attrs: {
                                                                        wrap: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-flex",
                                                                        {
                                                                          attrs: {
                                                                            xs6:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-layout",
                                                                            {
                                                                              attrs: {
                                                                                wrap:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-flex",
                                                                                {
                                                                                  attrs: {
                                                                                    xs1:
                                                                                      ""
                                                                                  }
                                                                                }
                                                                              ),
                                                                              _c(
                                                                                "v-flex",
                                                                                {
                                                                                  attrs: {
                                                                                    xs11:
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Reparaties"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-flex",
                                                                        {
                                                                          attrs: {
                                                                            xs6:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-layout",
                                                                            {
                                                                              attrs: {
                                                                                wrap:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-flex",
                                                                                {
                                                                                  attrs: {
                                                                                    xs2:
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Eenheid"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "v-flex",
                                                                                {
                                                                                  attrs: {
                                                                                    xs3:
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Aantal"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "v-flex",
                                                                                {
                                                                                  staticClass:
                                                                                    "text-xs-right",
                                                                                  attrs: {
                                                                                    xs2:
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Prijs"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "v-flex",
                                                                                {
                                                                                  staticClass:
                                                                                    "text-xs-right",
                                                                                  attrs: {
                                                                                    xs2:
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "BTW %"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "v-flex",
                                                                                {
                                                                                  attrs: {
                                                                                    xs1:
                                                                                      ""
                                                                                  }
                                                                                }
                                                                              ),
                                                                              _c(
                                                                                "v-flex",
                                                                                {
                                                                                  attrs: {
                                                                                    xs2:
                                                                                      ""
                                                                                  }
                                                                                }
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    xs12: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "draggable",
                                                                    _vm._b(
                                                                      {
                                                                        staticClass:
                                                                          "list-group",
                                                                        attrs: {
                                                                          options: {
                                                                            delay: 500
                                                                          },
                                                                          disabled:
                                                                            _vm.isLockedByUser
                                                                        },
                                                                        on: {
                                                                          start: function(
                                                                            $event
                                                                          ) {
                                                                            _vm.drag = true
                                                                          },
                                                                          end: function(
                                                                            $event
                                                                          ) {
                                                                            ;(_vm.drag = false),
                                                                              _vm.updateDamageRepairOrder()
                                                                          }
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm.currentDamageRepairs,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.currentDamageRepairs = $$v
                                                                          },
                                                                          expression:
                                                                            "currentDamageRepairs"
                                                                        }
                                                                      },
                                                                      "draggable",
                                                                      _vm.dragOptions,
                                                                      false
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "transition-group",
                                                                        _vm._l(
                                                                          _vm.currentDamageRepairs,
                                                                          function(
                                                                            repair,
                                                                            index
                                                                          ) {
                                                                            return _c(
                                                                              "div",
                                                                              {
                                                                                key:
                                                                                  repair.uuid,
                                                                                staticClass:
                                                                                  "list-group-item list__item",
                                                                                class: {
                                                                                  "list__item--repair":
                                                                                    repair.is_new
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-layout",
                                                                                  {
                                                                                    attrs: {
                                                                                      wrap:
                                                                                        "",
                                                                                      row:
                                                                                        "",
                                                                                      "align-center":
                                                                                        ""
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-flex",
                                                                                      {
                                                                                        attrs: {
                                                                                          xs6:
                                                                                            ""
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-layout",
                                                                                          {
                                                                                            attrs: {
                                                                                              wrap:
                                                                                                ""
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-flex",
                                                                                              {
                                                                                                attrs: {
                                                                                                  xs1:
                                                                                                    ""
                                                                                                }
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "v-icon",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "sortIcon"
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "drag_indicator"
                                                                                                    )
                                                                                                  ]
                                                                                                )
                                                                                              ],
                                                                                              1
                                                                                            ),
                                                                                            _c(
                                                                                              "v-flex",
                                                                                              {
                                                                                                attrs: {
                                                                                                  xs11:
                                                                                                    ""
                                                                                                }
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  " " +
                                                                                                    _vm._s(
                                                                                                      repair.form !==
                                                                                                        "custom"
                                                                                                        ? repair
                                                                                                            .type
                                                                                                            .name
                                                                                                        : repair.custom_name
                                                                                                    ) +
                                                                                                    " "
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _c(
                                                                                      "v-flex",
                                                                                      {
                                                                                        attrs: {
                                                                                          xs6:
                                                                                            ""
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-layout",
                                                                                          {
                                                                                            attrs: {
                                                                                              wrap:
                                                                                                "",
                                                                                              "align-center":
                                                                                                ""
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-flex",
                                                                                              {
                                                                                                attrs: {
                                                                                                  xs2:
                                                                                                    ""
                                                                                                }
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  " " +
                                                                                                    _vm._s(
                                                                                                      repair.form !==
                                                                                                        "custom"
                                                                                                        ? repair
                                                                                                            .type
                                                                                                            .unit
                                                                                                        : repair.custom_unit
                                                                                                    ) +
                                                                                                    " "
                                                                                                )
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "v-flex",
                                                                                              {
                                                                                                attrs: {
                                                                                                  xs3:
                                                                                                    ""
                                                                                                }
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "v-text-field",
                                                                                                  {
                                                                                                    attrs: {
                                                                                                      label:
                                                                                                        "aantal"
                                                                                                    },
                                                                                                    on: {
                                                                                                      change: function(
                                                                                                        $event
                                                                                                      ) {
                                                                                                        return _vm.updateRepairQuantity(
                                                                                                          repair,
                                                                                                          index
                                                                                                        )
                                                                                                      }
                                                                                                    },
                                                                                                    model: {
                                                                                                      value:
                                                                                                        repair.quantity,
                                                                                                      callback: function(
                                                                                                        $$v
                                                                                                      ) {
                                                                                                        _vm.$set(
                                                                                                          repair,
                                                                                                          "quantity",
                                                                                                          $$v
                                                                                                        )
                                                                                                      },
                                                                                                      expression:
                                                                                                        "repair.quantity"
                                                                                                    }
                                                                                                  }
                                                                                                )
                                                                                              ],
                                                                                              1
                                                                                            ),
                                                                                            _c(
                                                                                              "v-flex",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "text-xs-right",
                                                                                                attrs: {
                                                                                                  xs2:
                                                                                                    ""
                                                                                                }
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  " " +
                                                                                                    _vm._s(
                                                                                                      _vm._f(
                                                                                                        "currency"
                                                                                                      )(
                                                                                                        repair.total_incl_vat
                                                                                                      )
                                                                                                    ) +
                                                                                                    " "
                                                                                                )
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "v-flex",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "text-xs-right",
                                                                                                attrs: {
                                                                                                  xs2:
                                                                                                    ""
                                                                                                }
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  _vm._s(
                                                                                                    repair.vat
                                                                                                  ) +
                                                                                                    "%"
                                                                                                )
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "v-flex",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "text-xs-center customRepairIndicator",
                                                                                                attrs: {
                                                                                                  xs1:
                                                                                                    ""
                                                                                                }
                                                                                              },
                                                                                              [
                                                                                                repair.type &&
                                                                                                repair
                                                                                                  .type
                                                                                                  .calculation_model
                                                                                                  ? _c(
                                                                                                      "span",
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          " " +
                                                                                                            _vm._s(
                                                                                                              repair
                                                                                                                .type
                                                                                                                .calculation_model
                                                                                                                .name ||
                                                                                                                ""
                                                                                                            ) +
                                                                                                            " "
                                                                                                        )
                                                                                                      ]
                                                                                                    )
                                                                                                  : _vm._e(),
                                                                                                _c(
                                                                                                  "v-tooltip",
                                                                                                  {
                                                                                                    attrs: {
                                                                                                      bottom:
                                                                                                        ""
                                                                                                    },
                                                                                                    scopedSlots: _vm._u(
                                                                                                      [
                                                                                                        {
                                                                                                          key:
                                                                                                            "activator",
                                                                                                          fn: function(
                                                                                                            ref
                                                                                                          ) {
                                                                                                            var on =
                                                                                                              ref.on
                                                                                                            return [
                                                                                                              repair.form ===
                                                                                                              "custom"
                                                                                                                ? _c(
                                                                                                                    "v-icon",
                                                                                                                    _vm._g(
                                                                                                                      {
                                                                                                                        attrs: {
                                                                                                                          color:
                                                                                                                            "primary"
                                                                                                                        }
                                                                                                                      },
                                                                                                                      on
                                                                                                                    ),
                                                                                                                    [
                                                                                                                      _vm._v(
                                                                                                                        " info "
                                                                                                                      )
                                                                                                                    ]
                                                                                                                  )
                                                                                                                : _vm._e()
                                                                                                            ]
                                                                                                          }
                                                                                                        }
                                                                                                      ],
                                                                                                      null,
                                                                                                      true
                                                                                                    )
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "span",
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          "Aangepaste calculatie"
                                                                                                        )
                                                                                                      ]
                                                                                                    )
                                                                                                  ]
                                                                                                )
                                                                                              ],
                                                                                              1
                                                                                            ),
                                                                                            !_vm.isLockedByUser
                                                                                              ? _c(
                                                                                                  "v-flex",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "text-xs-right",
                                                                                                    attrs: {
                                                                                                      xs2:
                                                                                                        ""
                                                                                                    }
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "span",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "item__icon item__icon--action"
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "v-icon",
                                                                                                          {
                                                                                                            on: {
                                                                                                              click: function(
                                                                                                                $event
                                                                                                              ) {
                                                                                                                return _vm.editRepair(
                                                                                                                  repair
                                                                                                                )
                                                                                                              }
                                                                                                            }
                                                                                                          },
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              "edit"
                                                                                                            )
                                                                                                          ]
                                                                                                        )
                                                                                                      ],
                                                                                                      1
                                                                                                    ),
                                                                                                    _c(
                                                                                                      "span",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "item__icon item__icon--action delete"
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "v-icon",
                                                                                                          {
                                                                                                            on: {
                                                                                                              click: function(
                                                                                                                $event
                                                                                                              ) {
                                                                                                                return _vm.deleteRepair(
                                                                                                                  repair
                                                                                                                )
                                                                                                              }
                                                                                                            }
                                                                                                          },
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              "delete"
                                                                                                            )
                                                                                                          ]
                                                                                                        )
                                                                                                      ],
                                                                                                      1
                                                                                                    )
                                                                                                  ]
                                                                                                )
                                                                                              : _vm._e()
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          }
                                                                        ),
                                                                        0
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  staticClass:
                                                                    "list__item",
                                                                  attrs: {
                                                                    xs12: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-layout",
                                                                    {
                                                                      attrs: {
                                                                        wrap:
                                                                          "",
                                                                        "align-center":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-flex",
                                                                        {
                                                                          attrs: {
                                                                            xs6:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-layout",
                                                                            {
                                                                              attrs: {
                                                                                wrap:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-flex",
                                                                                {
                                                                                  attrs: {
                                                                                    xs1:
                                                                                      ""
                                                                                  }
                                                                                }
                                                                              ),
                                                                              _c(
                                                                                "v-flex",
                                                                                {
                                                                                  attrs: {
                                                                                    xs11:
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "strongText"
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Totaal"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-flex",
                                                                        {
                                                                          attrs: {
                                                                            xs6:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-layout",
                                                                            {
                                                                              attrs: {
                                                                                wrap:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-flex",
                                                                                {
                                                                                  attrs: {
                                                                                    xs2:
                                                                                      ""
                                                                                  }
                                                                                }
                                                                              ),
                                                                              _c(
                                                                                "v-flex",
                                                                                {
                                                                                  attrs: {
                                                                                    xs3:
                                                                                      ""
                                                                                  }
                                                                                }
                                                                              ),
                                                                              _c(
                                                                                "v-flex",
                                                                                {
                                                                                  staticClass:
                                                                                    "text-xs-right strongText",
                                                                                  attrs: {
                                                                                    xs2:
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "currency"
                                                                                        )(
                                                                                          _vm
                                                                                            .currentDamage
                                                                                            .totalCost
                                                                                        )
                                                                                      ) +
                                                                                      " "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "v-flex",
                                                                                {
                                                                                  staticClass:
                                                                                    "text-xs-right",
                                                                                  attrs: {
                                                                                    xs2:
                                                                                      ""
                                                                                  }
                                                                                }
                                                                              ),
                                                                              _c(
                                                                                "v-flex",
                                                                                {
                                                                                  attrs: {
                                                                                    xs1:
                                                                                      ""
                                                                                  }
                                                                                }
                                                                              ),
                                                                              _c(
                                                                                "v-flex",
                                                                                {
                                                                                  attrs: {
                                                                                    xs2:
                                                                                      ""
                                                                                  }
                                                                                }
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ])
                                                  ]
                                                : _vm._e(),
                                              !_vm.isLoadingRepairs &&
                                              _vm.currentDamage &&
                                              _vm.currentDamage.repairs &&
                                              _vm.currentDamage.repairs
                                                .length === 0
                                                ? [
                                                    _c(
                                                      "v-layout",
                                                      {
                                                        attrs: {
                                                          wrap: "",
                                                          "align-center": "",
                                                          "justify-center": ""
                                                        }
                                                      },
                                                      [
                                                        _c("NoDataCard", {
                                                          attrs: {
                                                            icon:
                                                              "construction",
                                                            text:
                                                              "Geen reparaties"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                : _vm._e(),
                                              !_vm.isLoadingRepairs &&
                                              _vm.currentDamage
                                                ? [
                                                    _c("div", {
                                                      staticClass:
                                                        "my-2 separator"
                                                    }),
                                                    !_vm.isLockedByUser
                                                      ? _c(
                                                          "v-layout",
                                                          {
                                                            attrs: { wrap: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs12: ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "AddRepair",
                                                                  {
                                                                    attrs: {
                                                                      types:
                                                                        _vm.repairTypes,
                                                                      damage:
                                                                        _vm.currentDamage
                                                                    },
                                                                    on: {
                                                                      created:
                                                                        _vm.addRepair
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ]
                                                : _vm._e()
                                            ],
                                            2
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "elementPanel elementPanelExpert",
                                          class: {
                                            "d-none":
                                              _vm.activeTabSub !== "tab-images"
                                          },
                                          style:
                                            "height: calc(100% - " +
                                            _vm.currentDamageContainerHeight +
                                            "px);"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "damageContainer__content"
                                            },
                                            [
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "v-layout",
                                                    {
                                                      attrs: {
                                                        wrap: "",
                                                        "px-3": ""
                                                      }
                                                    },
                                                    [
                                                      !_vm.isLockedByUser
                                                        ? _c(
                                                            "v-flex",
                                                            {
                                                              attrs: {
                                                                xs12: "",
                                                                "text-xs-right":
                                                                  ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-tooltip",
                                                                {
                                                                  attrs: {
                                                                    bottom: ""
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "activator",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var on =
                                                                            ref.on
                                                                          return [
                                                                            _c(
                                                                              "v-icon",
                                                                              _vm._g(
                                                                                {
                                                                                  staticClass:
                                                                                    "cursor-pointer",
                                                                                  attrs: {
                                                                                    color:
                                                                                      "primary"
                                                                                  },
                                                                                  nativeOn: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.openTransferDialog(
                                                                                        "Media",
                                                                                        "media"
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                on
                                                                              ),
                                                                              [
                                                                                _vm._v(
                                                                                  " content_copy "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    1007428399
                                                                  )
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Dupliceer naar schade"
                                                                    )
                                                                  ])
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-tooltip",
                                                                {
                                                                  attrs: {
                                                                    bottom: ""
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "activator",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var on =
                                                                            ref.on
                                                                          return [
                                                                            _c(
                                                                              "v-icon",
                                                                              _vm._g(
                                                                                {
                                                                                  staticClass:
                                                                                    "mx-2 cursor-pointer",
                                                                                  attrs: {
                                                                                    color:
                                                                                      "primary"
                                                                                  },
                                                                                  nativeOn: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      _vm.isAddingAttachment = true
                                                                                    }
                                                                                  }
                                                                                },
                                                                                on
                                                                              ),
                                                                              [
                                                                                _vm._v(
                                                                                  " playlist_add "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    3377740823
                                                                  )
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Foto toevoegen uit bijlagen"
                                                                    )
                                                                  ])
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-tooltip",
                                                                {
                                                                  attrs: {
                                                                    bottom: ""
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "activator",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var on =
                                                                            ref.on
                                                                          return [
                                                                            _c(
                                                                              "v-icon",
                                                                              _vm._g(
                                                                                {
                                                                                  staticClass:
                                                                                    "cursor-pointer",
                                                                                  attrs: {
                                                                                    color:
                                                                                      "secondary"
                                                                                  },
                                                                                  nativeOn: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      _vm.isUploadingFile = true
                                                                                    }
                                                                                  }
                                                                                },
                                                                                on
                                                                              ),
                                                                              [
                                                                                _vm._v(
                                                                                  " add "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    512662671
                                                                  )
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Foto toevoegen"
                                                                    )
                                                                  ])
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  ),
                                                  _c("div", {
                                                    staticClass:
                                                      "my-2 separator"
                                                  }),
                                                  _vm.currentDamage &&
                                                  _vm.currentDamage.media &&
                                                  _vm.currentDamage.media
                                                    .length !== 0
                                                    ? [
                                                        _c(
                                                          "draggable",
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "px-2 list-group",
                                                              attrs: {
                                                                disabled:
                                                                  _vm.isLockedByUser
                                                              },
                                                              on: {
                                                                start: function(
                                                                  $event
                                                                ) {
                                                                  _vm.drag = true
                                                                },
                                                                end: function(
                                                                  $event
                                                                ) {
                                                                  ;(_vm.drag = false),
                                                                    _vm.updateDamageMediaOrder()
                                                                }
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.currentDamageMedia,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.currentDamageMedia = $$v
                                                                },
                                                                expression:
                                                                  "currentDamageMedia"
                                                              }
                                                            },
                                                            "draggable",
                                                            _vm.dragOptions,
                                                            false
                                                          ),
                                                          [
                                                            _c(
                                                              "transition-group",
                                                              _vm._l(
                                                                _vm.currentDamageMedia,
                                                                function(
                                                                  media
                                                                ) {
                                                                  return _c(
                                                                    "div",
                                                                    {
                                                                      key:
                                                                        media.uuid,
                                                                      staticClass:
                                                                        "mediaContainer list-group-item",
                                                                      class:
                                                                        media.show_in_report !==
                                                                        "0"
                                                                          ? "pictureBorderShow"
                                                                          : "pictureBorderHidden"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "mediaContainer__actions"
                                                                        },
                                                                        [
                                                                          !_vm.isLockedByUser
                                                                            ? _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "action"
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    {
                                                                                      staticClass:
                                                                                        "sortIcon"
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "drag_indicator"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _vm._e(),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "action visibilityMedia",
                                                                              class: [
                                                                                media.show_in_report !==
                                                                                "0"
                                                                                  ? ""
                                                                                  : "pictureHidden",
                                                                                _vm.toggleInReportTimerOn
                                                                                  ? "toggleIsLoadingWrap"
                                                                                  : ""
                                                                              ],
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.toggleVisibilityMedia(
                                                                                    media
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-tooltip",
                                                                                {
                                                                                  attrs: {
                                                                                    top:
                                                                                      ""
                                                                                  },
                                                                                  scopedSlots: _vm._u(
                                                                                    [
                                                                                      {
                                                                                        key:
                                                                                          "activator",
                                                                                        fn: function(
                                                                                          ref
                                                                                        ) {
                                                                                          var on =
                                                                                            ref.on
                                                                                          return [
                                                                                            !_vm.toggleInReportTimerOn
                                                                                              ? _c(
                                                                                                  "div",
                                                                                                  _vm._g(
                                                                                                    {},
                                                                                                    on
                                                                                                  ),
                                                                                                  [
                                                                                                    media.show_in_report ===
                                                                                                    "0"
                                                                                                      ? _c(
                                                                                                          "v-icon",
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              "insert_drive_file"
                                                                                                            )
                                                                                                          ]
                                                                                                        )
                                                                                                      : _c(
                                                                                                          "v-icon",
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              "task"
                                                                                                            )
                                                                                                          ]
                                                                                                        )
                                                                                                  ],
                                                                                                  1
                                                                                                )
                                                                                              : _c(
                                                                                                  "div",
                                                                                                  _vm._g(
                                                                                                    {},
                                                                                                    on
                                                                                                  ),
                                                                                                  [
                                                                                                    _c(
                                                                                                      "v-icon",
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          "loop"
                                                                                                        )
                                                                                                      ]
                                                                                                    )
                                                                                                  ],
                                                                                                  1
                                                                                                )
                                                                                          ]
                                                                                        }
                                                                                      }
                                                                                    ],
                                                                                    null,
                                                                                    true
                                                                                  )
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "toggleVisibilityToolTip"
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " Naar "
                                                                                      ),
                                                                                      media.show_in_report ===
                                                                                      "0"
                                                                                        ? _c(
                                                                                            "span",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "wel"
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        : _c(
                                                                                            "span",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "niet"
                                                                                              )
                                                                                            ]
                                                                                          ),
                                                                                      _vm._v(
                                                                                        " in rapport "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "a",
                                                                            {
                                                                              attrs: {
                                                                                href:
                                                                                  media.original,
                                                                                target:
                                                                                  "_blank"
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "action"
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "remove_red_eye"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ]
                                                                          ),
                                                                          !_vm.isLockedByUser
                                                                            ? _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "action",
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.editMedia(
                                                                                        media
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "edit"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _vm._e(),
                                                                          !_vm.isLockedByUser
                                                                            ? _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "action",
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.deleteMedia(
                                                                                        media
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    {
                                                                                      staticClass:
                                                                                        "delete"
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "delete"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _vm._e()
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "img",
                                                                        {
                                                                          attrs: {
                                                                            src:
                                                                              media.thumb
                                                                          }
                                                                        }
                                                                      )
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    : _vm._e(),
                                                  _vm.currentDamage &&
                                                  _vm.currentDamage.media &&
                                                  _vm.currentDamage.media
                                                    .length === 0
                                                    ? [
                                                        _c(
                                                          "v-layout",
                                                          {
                                                            attrs: {
                                                              wrap: "",
                                                              "align-center":
                                                                "",
                                                              "justify-center":
                                                                ""
                                                            }
                                                          },
                                                          [
                                                            _c("NoDataCard", {
                                                              attrs: {
                                                                icon: "image",
                                                                text:
                                                                  "Geen foto's"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    : _vm._e()
                                                ],
                                                2
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "elementPanel elementPanelExpert",
                                          class: {
                                            "d-none":
                                              _vm.activeTabSub !==
                                              "tab-zelfopname"
                                          },
                                          style:
                                            "height: calc(100% - " +
                                            _vm.currentDamageContainerHeight +
                                            "px);"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "damageContainer__content"
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "px-3" },
                                                [
                                                  _vm.currentDamage
                                                    ? [
                                                        _vm.zelfopnameQuestions &&
                                                        _vm.currentDamage &&
                                                        !_vm.isLoading
                                                          ? _c(
                                                              "Questionnaire",
                                                              {
                                                                attrs: {
                                                                  questions:
                                                                    _vm.zelfopnameQuestions,
                                                                  answers:
                                                                    _vm
                                                                      .currentDamage
                                                                      .answers,
                                                                  report:
                                                                    _vm.report,
                                                                  damage:
                                                                    _vm.currentDamage,
                                                                  disabled:
                                                                    _vm.isLockedByUser,
                                                                  enableReadMode:
                                                                    _vm.$store
                                                                      .state
                                                                      .Auth
                                                                      .has_quick_questions
                                                                },
                                                                on: {
                                                                  damageAnswerSaved:
                                                                    _vm.damageAnswerSaved,
                                                                  reloadDamageAnswers:
                                                                    _vm.reloadDamageAnswers,
                                                                  reloadReportAnswers:
                                                                    _vm.reloadReportAnswers,
                                                                  onClickInfo:
                                                                    _vm.onClickInfo
                                                                }
                                                              }
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    : _vm._e(),
                                                  _vm.currentDamage &&
                                                  _vm.currentDamage.media &&
                                                  _vm.currentDamage.media
                                                    .length === 0
                                                    ? [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "fadedText"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Geen foto's"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    : _vm._e()
                                                ],
                                                2
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "v-tab-item",
                  {
                    staticClass: "fill-height",
                    attrs: {
                      lazy: true,
                      value: "tab-tools",
                      transition: false,
                      "reverse-transition": false
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "overflow-auto elementPanel fill-height" },
                      [
                        _c(
                          "v-layout",
                          { staticClass: "item__list", attrs: { wrap: "" } },
                          [
                            _c(
                              "v-flex",
                              { staticClass: "label", attrs: { xs12: "" } },
                              [
                                _c(
                                  "v-layout",
                                  [
                                    _c("v-flex", { attrs: { xs6: "" } }, [
                                      _vm._v("Maak een keuze")
                                    ]),
                                    _c("v-flex", { attrs: { xs6: "" } }, [
                                      _vm._v("Toelichting")
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm.report.isRegulier2021Report ||
                            _vm.report.isVesNulmetingReport
                              ? [
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "list__item",
                                      attrs: { xs12: "" }
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        {
                                          attrs: {
                                            wrap: "",
                                            "align-baseline": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs6: "" } },
                                            [
                                              _c(
                                                "MiButton",
                                                {
                                                  attrs: {
                                                    disabled:
                                                      _vm.isLockedByUser,
                                                    icon: "attach_money"
                                                  },
                                                  nativeOn: {
                                                    click: function($event) {
                                                      _vm.openConfirmBulkUpdate(
                                                        "BTWToepassen",
                                                        "Ja (Particulier / Woning)"
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " Met BTW (Particulier / Woning) "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c("v-flex", { attrs: { xs6: "" } }, [
                                            _vm._v(
                                              "Alle schades vermeerderen met BTW (Particulier / Woning)"
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "list__item",
                                      attrs: { xs12: "" }
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        {
                                          attrs: {
                                            wrap: "",
                                            "align-baseline": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs6: "" } },
                                            [
                                              _c(
                                                "MiButton",
                                                {
                                                  attrs: {
                                                    disabled:
                                                      _vm.isLockedByUser,
                                                    icon: "attach_money"
                                                  },
                                                  nativeOn: {
                                                    click: function($event) {
                                                      _vm.openConfirmBulkUpdate(
                                                        "BTWToepassen",
                                                        "Ja (Zakelijk)"
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v(" Met BTW (Zakelijk) ")]
                                              )
                                            ],
                                            1
                                          ),
                                          _c("v-flex", { attrs: { xs6: "" } }, [
                                            _vm._v(
                                              "Alle schades vermeerderen met BTW (Zakelijk en niet BTW plichtig)"
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "list__item",
                                      attrs: { xs12: "" }
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        {
                                          attrs: {
                                            wrap: "",
                                            "align-baseline": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs6: "" } },
                                            [
                                              _c(
                                                "MiButton",
                                                {
                                                  attrs: {
                                                    disabled:
                                                      _vm.isLockedByUser,
                                                    icon: "money_off"
                                                  },
                                                  nativeOn: {
                                                    click: function($event) {
                                                      _vm.openConfirmBulkUpdate(
                                                        "BTWToepassen",
                                                        "Nee (Zakelijk)"
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v(" Zonder BTW ")]
                                              )
                                            ],
                                            1
                                          ),
                                          _c("v-flex", { attrs: { xs6: "" } }, [
                                            _vm._v(
                                              "Alle schades zonder BTW (Zakelijk en BTW plichtig)"
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              : [
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "list__item",
                                      attrs: { xs12: "" }
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        {
                                          attrs: {
                                            wrap: "",
                                            "align-baseline": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs6: "" } },
                                            [
                                              _c(
                                                "MiButton",
                                                {
                                                  attrs: {
                                                    disabled: _vm.isLockedByUser
                                                  },
                                                  nativeOn: {
                                                    click: function($event) {
                                                      _vm.openConfirmBulkUpdate(
                                                        "damageVatRuling",
                                                        "Particulier / Woning (berekening vermeerderen met BTW)"
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " Particulier / Woning "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c("v-flex", { attrs: { xs6: "" } }, [
                                            _vm._v(
                                              "Alle schades op Particulier / Woning (berekening vermeerderen met BTW)"
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "list__item",
                                      attrs: { xs12: "" }
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        {
                                          attrs: {
                                            wrap: "",
                                            "align-baseline": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs6: "" } },
                                            [
                                              _c(
                                                "MiButton",
                                                {
                                                  attrs: {
                                                    disabled: _vm.isLockedByUser
                                                  },
                                                  nativeOn: {
                                                    click: function($event) {
                                                      _vm.openConfirmBulkUpdate(
                                                        "damageVatRuling",
                                                        "Zakelijk / Bedrijf (berekening zonder BTW)"
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " Zakelijk / Bedrijf (0% BTW) "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c("v-flex", { attrs: { xs6: "" } }, [
                                            _vm._v(
                                              "Alle schades op Zakelijk / Bedrijf (berekening zonder BTW)"
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "list__item",
                                      attrs: { xs12: "" }
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        {
                                          attrs: {
                                            wrap: "",
                                            "align-baseline": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs6: "" } },
                                            [
                                              _c(
                                                "MiButton",
                                                {
                                                  attrs: {
                                                    disabled: _vm.isLockedByUser
                                                  },
                                                  nativeOn: {
                                                    click: function($event) {
                                                      _vm.openConfirmBulkUpdate(
                                                        "damageVatRuling",
                                                        "Zakelijk / Bedrijf (berekening met 21% BTW)"
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " Zakelijk / Bedrijf (21% BTW) "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c("v-flex", { attrs: { xs6: "" } }, [
                                            _vm._v(
                                              "Alle schades op Zakelijk / Bedrijf (berekening met 21% BTW"
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                            _c(
                              "v-flex",
                              {
                                staticClass: "list__item",
                                attrs: { xs12: "" }
                              },
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { wrap: "", "align-baseline": "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      { attrs: { xs6: "" } },
                                      [
                                        _c(
                                          "MiButton",
                                          {
                                            attrs: {
                                              disabled: _vm.isLockedByUser,
                                              color: "primary",
                                              icon: "get_app"
                                            },
                                            nativeOn: {
                                              click: function($event) {
                                                _vm.isImportingDamages = true
                                              }
                                            }
                                          },
                                          [_vm._v(" Importeer schades ")]
                                        )
                                      ],
                                      1
                                    ),
                                    _c("v-flex", { attrs: { xs6: "" } }, [
                                      _vm._v(
                                        "Importeert alle schades van de gekozen dossier"
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "v-tab-item",
                  {
                    staticClass: "fill-height",
                    attrs: {
                      lazy: true,
                      value: "tab-bijlagen",
                      transition: false,
                      "reverse-transition": false
                    }
                  },
                  [
                    _c("div", { staticClass: "overflow-auto fill-height" }, [
                      _c(
                        "div",
                        { staticClass: "mb-3 elementPanel" },
                        [
                          _c("Plattegronden", {
                            attrs: {
                              report: _vm.report,
                              isEditor: true,
                              disabled: _vm.isLockedByUser
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mb-3 elementPanel" },
                        [
                          _c("Aanzichtfotos", {
                            attrs: {
                              report: _vm.report,
                              isEditor: true,
                              disabled: _vm.isLockedByUser
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]
                ),
                _c(
                  "v-tab-item",
                  {
                    staticClass: "fill-height",
                    attrs: {
                      lazy: true,
                      value: "tab-reject",
                      transition: false,
                      "reverse-transition": false
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "overflow-auto elementPanel fill-height" },
                      [
                        !_vm.isLoading
                          ? _c(
                              "v-layout",
                              { attrs: { row: "", wrap: "" } },
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        class: {
                                          "reject-border":
                                            _vm.report.reject_reasons.length ||
                                            _vm.report.internal_reject_comment
                                              .length
                                        }
                                      },
                                      [
                                        _vm.report
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "elementPanel profile"
                                              },
                                              [
                                                _vm.isLoading
                                                  ? _c("LoaderCard", {
                                                      attrs: {
                                                        flat: "",
                                                        type: "spinner--center",
                                                        minHeight: "300px"
                                                      }
                                                    })
                                                  : _vm._e(),
                                                !_vm.isLoading
                                                  ? [
                                                      _c(
                                                        "v-layout",
                                                        {
                                                          staticClass:
                                                            "profileContainer",
                                                          attrs: {
                                                            row: "",
                                                            wrap: ""
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            [
                                                              [
                                                                _c(
                                                                  "h1",
                                                                  {
                                                                    staticClass:
                                                                      "profile__userName"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .report
                                                                          .case_number
                                                                      )
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "profile__userRoles"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .report
                                                                            .type
                                                                            ? _vm
                                                                                .report
                                                                                .type
                                                                                .name
                                                                            : ""
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            ],
                                                            2
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-layout",
                                                        { attrs: { row: "" } },
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "infoPanelColumn",
                                                              attrs: { sm4: "" }
                                                            },
                                                            [
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "infoPanelColumn__fadedText"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Naam aanvrager"
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "infoPanelColumn",
                                                              attrs: { sm8: "" }
                                                            },
                                                            [
                                                              _c("p", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.report
                                                                      .applicant
                                                                      ? _vm
                                                                          .report
                                                                          .applicant
                                                                          .name
                                                                      : ""
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-layout",
                                                        { attrs: { row: "" } },
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "infoPanelColumn",
                                                              attrs: { sm4: "" }
                                                            },
                                                            [
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "infoPanelColumn__fadedText"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Adres"
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "infoPanelColumn",
                                                              attrs: { sm8: "" }
                                                            },
                                                            [
                                                              _vm.report.address
                                                                ? _c(
                                                                    "a",
                                                                    {
                                                                      attrs: {
                                                                        href: _vm.report.googleMapUrl(),
                                                                        target:
                                                                          "_blank"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .report
                                                                              .address
                                                                              .street
                                                                          ) +
                                                                          " " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .report
                                                                              .address
                                                                              .number
                                                                          ) +
                                                                          " " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .report
                                                                              .address
                                                                              .number_add
                                                                          ) +
                                                                          ", " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .report
                                                                              .address
                                                                              .postcode
                                                                          ) +
                                                                          " " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .report
                                                                              .address
                                                                              .city
                                                                          ) +
                                                                          " "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-layout",
                                                        { attrs: { row: "" } },
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "infoPanelColumn",
                                                              attrs: { sm4: "" }
                                                            },
                                                            [
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "infoPanelColumn__fadedText"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Opgestuurd"
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "infoPanelColumn",
                                                              attrs: { sm8: "" }
                                                            },
                                                            [
                                                              _c("p", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.report
                                                                        .submitted_at ===
                                                                        "0000-00-00 00:00:00"
                                                                        ? "-"
                                                                        : _vm.formatDateSeconds(
                                                                            _vm
                                                                              .report
                                                                              .submitted_at
                                                                          )
                                                                    ) +
                                                                    " "
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-layout",
                                                        { attrs: { row: "" } },
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "infoPanelColumn",
                                                              attrs: { sm4: "" }
                                                            },
                                                            [
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "infoPanelColumn__fadedText"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Rapport type"
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "infoPanelColumn",
                                                              attrs: { sm8: "" }
                                                            },
                                                            [
                                                              _c("p", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.report
                                                                      .type
                                                                      ? _vm
                                                                          .report
                                                                          .type
                                                                          .name
                                                                      : ""
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-layout",
                                                        { attrs: { row: "" } },
                                                        [
                                                          _vm.report
                                                            .isAannemersReport
                                                            ? _c(
                                                                "v-flex",
                                                                {
                                                                  staticClass:
                                                                    "infoPanelColumn",
                                                                  attrs: {
                                                                    sm4: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "p",
                                                                    {
                                                                      staticClass:
                                                                        "infoPanelColumn__fadedText"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Naam Aannemer"
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          !_vm.report
                                                            .isAannemersReport
                                                            ? _c(
                                                                "v-flex",
                                                                {
                                                                  staticClass:
                                                                    "infoPanelColumn",
                                                                  attrs: {
                                                                    sm4: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "p",
                                                                    {
                                                                      staticClass:
                                                                        "infoPanelColumn__fadedText"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Naam Expert"
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "infoPanelColumn",
                                                              attrs: { sm8: "" }
                                                            },
                                                            [
                                                              _vm.report.expert
                                                                ? _c("p", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .report
                                                                          .expert
                                                                          .name
                                                                      )
                                                                    )
                                                                  ])
                                                                : _vm._e()
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm.report
                                                        .isAannemersReport
                                                        ? _c(
                                                            "v-layout",
                                                            {
                                                              attrs: { row: "" }
                                                            },
                                                            [
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  staticClass:
                                                                    "infoPanelColumn",
                                                                  attrs: {
                                                                    sm4: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "p",
                                                                    {
                                                                      staticClass:
                                                                        "infoPanelColumn__fadedText"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Naam Expert"
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              ),
                                                              _vm.report
                                                                .second_expert
                                                                ? _c(
                                                                    "v-flex",
                                                                    {
                                                                      staticClass:
                                                                        "infoPanelColumn",
                                                                      attrs: {
                                                                        sm8: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c("p", [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm
                                                                              .report
                                                                              .second_expert
                                                                              .name
                                                                          )
                                                                        )
                                                                      ])
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _c(
                                                        "v-layout",
                                                        { attrs: { row: "" } },
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "infoPanelColumn",
                                                              attrs: { sm4: "" }
                                                            },
                                                            [
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "infoPanelColumn__fadedText"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "profile.reportTag"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "infoPanelColumn",
                                                              attrs: { sm8: "" }
                                                            },
                                                            _vm._l(
                                                              _vm.report.tags,
                                                              function(
                                                                tag,
                                                                index
                                                              ) {
                                                                return _c(
                                                                  "p",
                                                                  {
                                                                    key: index
                                                                  },
                                                                  [
                                                                    _vm.report
                                                                      .tags
                                                                      .length >
                                                                    1
                                                                      ? _c(
                                                                          "span",
                                                                          [
                                                                            tag[0]
                                                                              ? _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          tag.name
                                                                                        ) +
                                                                                        " "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              : _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "," +
                                                                                        _vm._s(
                                                                                          tag.name
                                                                                        )
                                                                                    )
                                                                                  ]
                                                                                )
                                                                          ]
                                                                        )
                                                                      : _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  tag.name
                                                                                ) +
                                                                                " "
                                                                            )
                                                                          ]
                                                                        )
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-layout",
                                                        { attrs: { row: "" } },
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "infoPanelColumn",
                                                              attrs: { sm4: "" }
                                                            },
                                                            [
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "infoPanelColumn__fadedText"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Bouwjaar"
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "infoPanelColumn",
                                                              attrs: { sm8: "" }
                                                            },
                                                            [
                                                              _c("p", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm
                                                                      .generalKeys
                                                                      .BouwJaar
                                                                      ? _vm
                                                                          .generalKeys
                                                                          .BouwJaar
                                                                          .value
                                                                      : "-"
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-layout",
                                                        { attrs: { row: "" } },
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "infoPanelColumn",
                                                              attrs: { sm4: "" }
                                                            },
                                                            [
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "infoPanelColumn__fadedText"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Eigendom sinds"
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "infoPanelColumn",
                                                              attrs: { sm8: "" }
                                                            },
                                                            [
                                                              _c("p", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm
                                                                      .generalKeys
                                                                      .EigendomSinds
                                                                      ? _vm
                                                                          .generalKeys
                                                                          .EigendomSinds
                                                                          .value
                                                                      : "-"
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-layout",
                                                        { attrs: { row: "" } },
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "infoPanelColumn",
                                                              attrs: { sm4: "" }
                                                            },
                                                            [
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "infoPanelColumn__fadedText"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Verhaal aanvrager"
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "infoPanelColumn",
                                                              attrs: { sm8: "" }
                                                            },
                                                            [
                                                              _c("p", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm
                                                                      .generalKeys
                                                                      .storyOfApplicant
                                                                      ? _vm
                                                                          .generalKeys
                                                                          .storyOfApplicant
                                                                          .value
                                                                      : "-"
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-layout",
                                                        { attrs: { row: "" } },
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "infoPanelColumn",
                                                              attrs: { sm4: "" }
                                                            },
                                                            [
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "infoPanelColumn__fadedText"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Opmerking deskundige"
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "infoPanelColumn",
                                                              attrs: { sm8: "" }
                                                            },
                                                            [
                                                              _c("p", {
                                                                domProps: {
                                                                  innerHTML: _vm._s(
                                                                    _vm.sanitizeString(
                                                                      _vm
                                                                        .generalKeys
                                                                        .ExpertComment
                                                                        ? _vm
                                                                            .generalKeys
                                                                            .ExpertComment
                                                                            .value
                                                                        : "-"
                                                                    )
                                                                  )
                                                                }
                                                              }),
                                                              _c("p", {
                                                                domProps: {
                                                                  innerHTML: _vm._s(
                                                                    _vm.sanitizeString(
                                                                      _vm
                                                                        .generalKeys
                                                                        .OpmerkingDeskundige
                                                                        ? _vm
                                                                            .generalKeys
                                                                            .OpmerkingDeskundige
                                                                            .value
                                                                        : "-"
                                                                    )
                                                                  )
                                                                }
                                                              })
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-layout",
                                                        { attrs: { row: "" } },
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "infoPanelColumn",
                                                              attrs: { sm4: "" }
                                                            },
                                                            [
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "infoPanelColumn__fadedText"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Opmerking aanvrager"
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "infoPanelColumn",
                                                              attrs: { sm8: "" }
                                                            },
                                                            [
                                                              _c("p", {
                                                                domProps: {
                                                                  innerHTML: _vm._s(
                                                                    _vm.sanitizeString(
                                                                      _vm
                                                                        .generalKeys
                                                                        .relevantRemarksApplicant
                                                                        ? _vm
                                                                            .generalKeys
                                                                            .relevantRemarksApplicant
                                                                            .value
                                                                        : "-"
                                                                    )
                                                                  )
                                                                }
                                                              }),
                                                              _c("p", {
                                                                domProps: {
                                                                  innerHTML: _vm._s(
                                                                    _vm.sanitizeString(
                                                                      _vm
                                                                        .generalKeys
                                                                        .OpmerkingAanvrager
                                                                        ? _vm
                                                                            .generalKeys
                                                                            .OpmerkingAanvrager
                                                                            .value
                                                                        : "-"
                                                                    )
                                                                  )
                                                                }
                                                              })
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-layout",
                                                        { attrs: { row: "" } },
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "infoPanelColumn",
                                                              attrs: { sm4: "" }
                                                            },
                                                            [
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "infoPanelColumn__fadedText"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Geaccepteerd"
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "infoPanelColumn",
                                                              attrs: { sm8: "" }
                                                            },
                                                            [
                                                              _c("p", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.report
                                                                        .approved_at ===
                                                                        "0000-00-00 00:00:00"
                                                                        ? "-"
                                                                        : _vm.formatDateSeconds(
                                                                            _vm
                                                                              .report
                                                                              .approved_at
                                                                          )
                                                                    ) +
                                                                    " "
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-layout",
                                                        { attrs: { row: "" } },
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "infoPanelColumn",
                                                              attrs: { sm4: "" }
                                                            },
                                                            [
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "infoPanelColumn__fadedText"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Totaal schadebedrag incl. BTW"
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _vm.report
                                                            .total_repairs
                                                            ? _c(
                                                                "v-flex",
                                                                {
                                                                  staticClass:
                                                                    "infoPanelColumn",
                                                                  attrs: {
                                                                    sm8: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c("p", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "currency"
                                                                        )(
                                                                          _vm
                                                                            .report
                                                                            .total_repairs
                                                                            .total_in
                                                                        )
                                                                      )
                                                                    )
                                                                  ])
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-layout",
                                                        { attrs: { row: "" } },
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "infoPanelColumn",
                                                              attrs: { sm4: "" }
                                                            },
                                                            [
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "infoPanelColumn__fadedText"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Interne afwijsreden"
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "infoPanelColumn",
                                                              attrs: { sm8: "" }
                                                            },
                                                            [
                                                              _c("v-select", {
                                                                attrs: {
                                                                  items:
                                                                    _vm.reportRejectReasons,
                                                                  "item-text":
                                                                    "name",
                                                                  "item-value":
                                                                    "id",
                                                                  multiple: "",
                                                                  "return-object":
                                                                    "",
                                                                  placeholder:
                                                                    "Afkeurredenen"
                                                                },
                                                                on: {
                                                                  change:
                                                                    _vm.debounceReasoning
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.report
                                                                      .reject_reasons,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.report,
                                                                      "reject_reasons",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "report.reject_reasons"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-layout",
                                                        { attrs: { row: "" } },
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "infoPanelColumn",
                                                              attrs: { sm4: "" }
                                                            },
                                                            [
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "infoPanelColumn__fadedText"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Interne afwijsuitleg"
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "infoPanelColumn",
                                                              attrs: { sm8: "" }
                                                            },
                                                            [
                                                              _c("v-textarea", {
                                                                staticClass:
                                                                  "pt-0 mt-0",
                                                                attrs: {
                                                                  placeholder:
                                                                    "Uitleg voor het afkeuren",
                                                                  name:
                                                                    "explanation",
                                                                  disabled:
                                                                    _vm.isLockedByUser
                                                                },
                                                                on: {
                                                                  input:
                                                                    _vm.debounceExplanation
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.report
                                                                      .internal_reject_comment,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.report,
                                                                      "internal_reject_comment",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "report.internal_reject_comment"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm.report.validations
                                                        ? _c(
                                                            "v-layout",
                                                            {
                                                              staticClass:
                                                                "pl-0 item__list item__list--reports",
                                                              attrs: {
                                                                wrap: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._l(
                                                                _vm.report
                                                                  .validations,
                                                                function(
                                                                  validation,
                                                                  key
                                                                ) {
                                                                  return [
                                                                    validation !==
                                                                    "ok"
                                                                      ? _c(
                                                                          "v-flex",
                                                                          {
                                                                            key: key,
                                                                            staticClass:
                                                                              "list__item",
                                                                            attrs: {
                                                                              xs12:
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "ValidationWarning",
                                                                              {
                                                                                attrs: {
                                                                                  validation: validation,
                                                                                  validationKey: key
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e()
                                                                  ]
                                                                }
                                                              )
                                                            ],
                                                            2
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            )
                                          : _vm._e()
                                      ]
                                    ),
                                    _vm.damages
                                      ? [
                                          _c("DamagePanel", {
                                            ref: "damagePanel",
                                            attrs: {
                                              damages: _vm.damages,
                                              includes: [
                                                "validations",
                                                "reject_reasons",
                                                "media",
                                                "repairs"
                                              ],
                                              visibility: _vm.damageVisibility,
                                              panelsFromStorage:
                                                _vm.panelsFromStorage,
                                              disabled: _vm.isLockedByUser
                                            },
                                            on: {
                                              reloadDamages: _vm.initialize,
                                              panelChanged: function($event) {
                                                return _vm.handlePanelChanged(
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      : _vm._e()
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            ),
            _c("ApprovalBar", {
              attrs: { backUrl: "/reports/" + _vm.report.uuid },
              scopedSlots: _vm._u(
                [
                  {
                    key: "content",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          { staticClass: "actionContainer" },
                          [
                            _c(
                              "MiButton",
                              {
                                attrs: {
                                  disabled:
                                    !_vm.isValidDate || _vm.isLockedByUser,
                                  color: "primary",
                                  small: "true",
                                  icon: "save"
                                },
                                nativeOn: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.updateReport()
                                  }
                                }
                              },
                              [_vm._v(" Opslaan ")]
                            )
                          ],
                          1
                        ),
                        _vm.hasRejectButton
                          ? _c(
                              "div",
                              { staticClass: "actionContainer" },
                              [
                                _c(
                                  "MiButton",
                                  {
                                    attrs: {
                                      disabled:
                                        !_vm.isValidDate || _vm.isLockedByUser,
                                      color: "warning",
                                      small: "true",
                                      icon: "cancel"
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.toggleApproveDialog(
                                          _vm.ReportStatus.OPNAME_REJECTED,
                                          "Afkeuren",
                                          "rejected"
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(" Terugzetten ")]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        (_vm.$store.state.Auth.hasRole(
                          _vm.userRoles.ManagerRoles
                        ) ||
                          _vm.$store.state.Auth.hasRole(
                            _vm.userRoles.DeskundigeRoles
                          )) &&
                        _vm.isSupervisor
                          ? _c(
                              "div",
                              { staticClass: "actionContainer" },
                              [
                                _c(
                                  "MiButton",
                                  {
                                    attrs: {
                                      disabled: _vm.isLockedByUser,
                                      color: "warning",
                                      small: "true",
                                      icon: "cancel"
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.toggleApproveDialog(
                                          _vm.ReportStatus.IN_PROGRESS,
                                          "Terugzetten naar:",
                                          "in_progress"
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(" Terugzetten ")]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        !_vm.isObjection &&
                        !_vm.isStuwmeer &&
                        !_vm.isZienswijze &&
                        !_vm.isOpname &&
                        !_vm.isMutatieWoning
                          ? [
                              _vm.$store.state.Auth.hasRole(
                                _vm.userRoles.ManagerRoles
                              ) &&
                              _vm.isVoorControle &&
                              !_vm.report.isVesNulmetingReport
                                ? _c(
                                    "div",
                                    { staticClass: "actionContainer" },
                                    [
                                      _c(
                                        "MiButton",
                                        {
                                          staticClass: "mr-2",
                                          attrs: {
                                            disabled: _vm.isLockedByUser,
                                            color: "warning",
                                            small: "true",
                                            icon: "cancel"
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.toggleApproveDialog(
                                                _vm.ReportStatus.REJECTED,
                                                "Versturen naar:",
                                                "rejected"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(" Terugzetten ")]
                                      ),
                                      !_vm.isVeldwerkRejected
                                        ? [
                                            _c(
                                              "MiButton",
                                              {
                                                attrs: {
                                                  disabled: _vm.isLockedByUser,
                                                  color: "alert",
                                                  small: "true",
                                                  icon: "check_circle"
                                                },
                                                nativeOn: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    return _vm.toggleApproveDialog(
                                                      _vm.ReportStatus.APPROVED,
                                                      "Versturen naar:",
                                                      "approved"
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v(" Ondertekenen ")]
                                            )
                                          ]
                                        : _vm._e(),
                                      _vm.isVeldwerkRejected
                                        ? [
                                            _c(
                                              "MiButton",
                                              {
                                                attrs: {
                                                  disabled: _vm.isLockedByUser,
                                                  color: "alert",
                                                  small: "true",
                                                  icon: "check_circle"
                                                },
                                                nativeOn: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    return _vm.toggleApproveDialog(
                                                      _vm.ReportStatus
                                                        .VELDWERK_CORRECTED,
                                                      "Versturen naar:",
                                                      "approved"
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v(" Ondertekenen ")]
                                            )
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              !_vm.report.isVesNulmetingReport
                                ? _c(
                                    "div",
                                    { staticClass: "actionContainer" },
                                    [
                                      _c(
                                        "MiButton",
                                        {
                                          attrs: {
                                            disabled:
                                              !_vm.isValidDate ||
                                              _vm.isLockedByUser,
                                            color: "blue-magenta",
                                            small: "true",
                                            icon: "check_circle"
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.toggleApproveDialog(
                                                _vm.ReportStatus.SUBMITTED,
                                                "Versturen naar:",
                                                "approved"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(" Voorcontrole ")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "actionContainer" },
                                [
                                  _vm.report && !_vm.report.isVesNulmetingReport
                                    ? _c(
                                        "MiButton",
                                        {
                                          attrs: {
                                            disabled:
                                              !_vm.isValidDate ||
                                              _vm.isLockedByUser,
                                            color: "yellow",
                                            small: "true",
                                            icon: "check_circle"
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.toggleApproveDialog(
                                                _vm.ReportStatus.SUPERVISOR,
                                                "Versturen naar:",
                                                "approved"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(" Supervisor ")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          : _vm._e(),
                        _vm.isObjection
                          ? [
                              _vm.report &&
                              _vm.report.objection &&
                              _vm.report.objection.is_hearing
                                ? _c(
                                    "div",
                                    { staticClass: "actionContainer" },
                                    [
                                      _c(
                                        "MiButton",
                                        {
                                          attrs: {
                                            disabled:
                                              _vm.isLockedByUser ||
                                              !_vm.currentUserIsReportExpert ||
                                              !_vm.hoorzittingHasPassed,
                                            color: "success",
                                            small: "true",
                                            icon: "check_circle"
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.toggleApproveDialog(
                                                _vm.ReportStatus
                                                  .OBJECTION_PENDING_CLOSE,
                                                "Versturen naar:",
                                                "approved"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(" Bezwaar goedkeuren ")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.$store.state.Auth.hasRole(
                                _vm.userRoles.ManagerRoles
                              ) && _vm.isVoorControle
                                ? _c(
                                    "div",
                                    { staticClass: "actionContainer" },
                                    [
                                      _c(
                                        "MiButton",
                                        {
                                          staticClass: "mr-2",
                                          attrs: {
                                            disabled: _vm.isLockedByUser,
                                            color: "warning",
                                            small: "true",
                                            icon: "cancel"
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.toggleApproveDialog(
                                                _vm.ReportStatus
                                                  .OBJECTION_REJECTED,
                                                "Versturen naar:",
                                                "rejected"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(" Afkeuren ")]
                                      ),
                                      !_vm.isObjectionRejected
                                        ? [
                                            _c(
                                              "MiButton",
                                              {
                                                attrs: {
                                                  disabled: _vm.isLockedByUser,
                                                  color: "alert",
                                                  small: "true",
                                                  icon: "check_circle"
                                                },
                                                nativeOn: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    return _vm.toggleApproveDialog(
                                                      _vm.ReportStatus
                                                        .OBJECTION_APPROVED,
                                                      "Versturen naar:",
                                                      "approved"
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v(" Ondertekenen ")]
                                            )
                                          ]
                                        : _vm._e(),
                                      _vm.isObjectionRejected
                                        ? [
                                            _c(
                                              "MiButton",
                                              {
                                                attrs: {
                                                  disabled: _vm.isLockedByUser,
                                                  color: "alert",
                                                  small: "true",
                                                  icon: "check_circle"
                                                },
                                                nativeOn: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    return _vm.toggleApproveDialog(
                                                      _vm.ReportStatus
                                                        .OBJECTION_CORRECTED,
                                                      "Versturen naar:",
                                                      "approved"
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v(" Ondertekenen ")]
                                            )
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "actionContainer" },
                                [
                                  _c(
                                    "MiButton",
                                    {
                                      attrs: {
                                        disabled:
                                          !_vm.isValidDate ||
                                          _vm.isLockedByUser,
                                        color: "success",
                                        small: "true",
                                        icon: "check_circle"
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.toggleApproveDialog(
                                            _vm.ReportStatus.OBJECTION_CHECKING,
                                            "Versturen naar:",
                                            "approved"
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(" Inleveren ")]
                                  )
                                ],
                                1
                              )
                            ]
                          : _vm._e(),
                        _vm.isStuwmeer
                          ? [
                              _vm.$store.state.Auth.hasRole(
                                _vm.userRoles.ManagerRoles
                              ) && _vm.isVoorControle
                                ? _c(
                                    "div",
                                    { staticClass: "actionContainer" },
                                    [
                                      _c(
                                        "MiButton",
                                        {
                                          staticClass: "mr-2",
                                          attrs: {
                                            disabled: _vm.isLockedByUser,
                                            color: "warning",
                                            small: "true",
                                            icon: "cancel"
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.toggleApproveDialog(
                                                _vm.ReportStatus.SMR_REJECTED,
                                                "Versturen naar:",
                                                "rejected"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(" Afkeuren ")]
                                      ),
                                      !_vm.isStuwmeerRejected
                                        ? [
                                            _c(
                                              "MiButton",
                                              {
                                                attrs: {
                                                  disabled: _vm.isLockedByUser,
                                                  color: "alert",
                                                  small: "true",
                                                  icon: "check_circle"
                                                },
                                                nativeOn: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    return _vm.toggleApproveDialog(
                                                      _vm.ReportStatus
                                                        .SMR_APPROVED,
                                                      "Versturen naar:",
                                                      "approved"
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v(" Ondertekenen ")]
                                            )
                                          ]
                                        : _vm._e(),
                                      _vm.isStuwmeerRejected
                                        ? [
                                            _c(
                                              "MiButton",
                                              {
                                                attrs: {
                                                  disabled: _vm.isLockedByUser,
                                                  color: "alert",
                                                  small: "true",
                                                  icon: "check_circle"
                                                },
                                                nativeOn: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    return _vm.toggleApproveDialog(
                                                      _vm.ReportStatus
                                                        .SMR_CORRECTED,
                                                      "Versturen naar:",
                                                      "approved"
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v(" Ondertekenen ")]
                                            )
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "actionContainer" },
                                [
                                  _c(
                                    "MiButton",
                                    {
                                      attrs: {
                                        disabled:
                                          !_vm.isValidDate ||
                                          _vm.isLockedByUser,
                                        color: "success",
                                        small: "true",
                                        icon: "check_circle"
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.toggleApproveDialog(
                                            _vm.ReportStatus.SMR_CHECKING,
                                            "Versturen naar:",
                                            "approved"
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(" Inleveren ")]
                                  )
                                ],
                                1
                              )
                            ]
                          : _vm._e(),
                        _vm.isZienswijze
                          ? [
                              _vm.$store.state.Auth.hasRole(
                                _vm.userRoles.ManagerRoles
                              ) && _vm.isVoorControle
                                ? _c(
                                    "div",
                                    { staticClass: "actionContainer" },
                                    [
                                      _c(
                                        "MiButton",
                                        {
                                          staticClass: "mr-2",
                                          attrs: {
                                            disabled: _vm.isLockedByUser,
                                            color: "warning",
                                            small: "true",
                                            icon: "cancel"
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.toggleApproveDialog(
                                                _vm.ReportStatus
                                                  .ZIENSWIJZE_REJECTED,
                                                "Versturen naar:",
                                                "rejected"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(" Afkeuren ")]
                                      ),
                                      !_vm.isZienswijzeRejected
                                        ? [
                                            _c(
                                              "MiButton",
                                              {
                                                attrs: {
                                                  disabled: _vm.isLockedByUser,
                                                  color: "alert",
                                                  small: "true",
                                                  icon: "check_circle"
                                                },
                                                nativeOn: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    return _vm.toggleApproveDialog(
                                                      _vm.ReportStatus
                                                        .ZIENSWIJZE_APPROVED,
                                                      "Versturen naar:",
                                                      "approved"
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v(" Ondertekenen ")]
                                            )
                                          ]
                                        : _vm._e(),
                                      _vm.isZienswijzeRejected
                                        ? [
                                            _c(
                                              "MiButton",
                                              {
                                                attrs: {
                                                  disabled: _vm.isLockedByUser,
                                                  color: "alert",
                                                  small: "true",
                                                  icon: "check_circle"
                                                },
                                                nativeOn: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    return _vm.toggleApproveDialog(
                                                      _vm.ReportStatus
                                                        .ZIENSWIJZE_CORRECTED,
                                                      "Versturen naar:",
                                                      "approved"
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v(" Ondertekenen ")]
                                            )
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "actionContainer" },
                                [
                                  _c(
                                    "MiButton",
                                    {
                                      attrs: {
                                        disabled:
                                          !_vm.isValidDate ||
                                          _vm.isLockedByUser,
                                        color: "success",
                                        small: "true",
                                        icon: "check_circle"
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.toggleApproveDialog(
                                            _vm.ReportStatus
                                              .ZIENSWIJZE_CHECKING,
                                            "Versturen naar:",
                                            "approved"
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(" Inleveren ")]
                                  )
                                ],
                                1
                              )
                            ]
                          : _vm._e(),
                        _vm.isOpname && !_vm.report.isVesNulmetingReport
                          ? _c(
                              "div",
                              { staticClass: "actionContainer" },
                              [
                                _c(
                                  "MiButton",
                                  {
                                    attrs: {
                                      disabled:
                                        !_vm.isValidDate || _vm.isLockedByUser,
                                      color: "success",
                                      small: "true",
                                      icon: "check_circle"
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.toggleApproveDialog(
                                          _vm.ReportStatus.OPNAME_DONE,
                                          "Versturen naar:",
                                          "approved"
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(" Inleveren ")]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isOpnameRejected && !_vm.report.isVesNulmetingReport
                          ? _c(
                              "div",
                              { staticClass: "actionContainer" },
                              [
                                _c(
                                  "MiButton",
                                  {
                                    attrs: {
                                      disabled:
                                        !_vm.isValidDate || _vm.isLockedByUser,
                                      color: "success",
                                      small: "true",
                                      icon: "check_circle"
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.toggleApproveDialog(
                                          _vm.ReportStatus.IN_PROGRESS,
                                          "Versturen naar:",
                                          "approved"
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(" Inleveren ")]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.report.isVesNulmetingReport
                          ? _c(
                              "div",
                              { staticClass: "actionContainer" },
                              [
                                _c(
                                  "MiButton",
                                  {
                                    attrs: {
                                      disabled:
                                        !_vm.isValidDate || _vm.isLockedByUser,
                                      color: "success",
                                      small: "true",
                                      icon: "check_circle"
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.toggleApproveDialog(
                                          _vm.ReportStatus.CHECKING,
                                          "Versturen naar:",
                                          "approved"
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(" Inleveren ")]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isMutatieWoning
                          ? _c(
                              "div",
                              { staticClass: "actionContainer" },
                              [
                                _c(
                                  "MiButton",
                                  {
                                    attrs: {
                                      disabled:
                                        !_vm.isValidDate || _vm.isLockedByUser,
                                      color: "success",
                                      small: "true",
                                      icon: "check_circle"
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.toggleApproveDialog(
                                          _vm.ReportStatus
                                            .OPNAME_WERKVOORBEREIDING_DONE,
                                          "Versturen naar:",
                                          "approved"
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(" Inleveren ")]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                1917725124
              )
            })
          ]
        : _vm._e(),
      _vm.approvalDialogOpen
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.approvalDialogOpen,
                callback: function($$v) {
                  _vm.approvalDialogOpen = $$v
                },
                expression: "approvalDialogOpen"
              }
            },
            [
              _c("ApprovalDialog", {
                attrs: {
                  approval: _vm.approval,
                  report: _vm.report,
                  title: _vm.title,
                  status: _vm.status,
                  role: "expert",
                  expertReject: true,
                  showCommentField: true
                },
                model: {
                  value: _vm.approvalDialogOpen,
                  callback: function($$v) {
                    _vm.approvalDialogOpen = $$v
                  },
                  expression: "approvalDialogOpen"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.isAddingAttachment
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "900", persistent: true },
              model: {
                value: _vm.isAddingAttachment,
                callback: function($$v) {
                  _vm.isAddingAttachment = $$v
                },
                expression: "isAddingAttachment"
              }
            },
            [
              _c(
                "DefaultDialog",
                {
                  on: {
                    close: function($event) {
                      _vm.isAddingAttachment = false
                      _vm.selectedAttachments = []
                    }
                  }
                },
                [
                  _c("template", { slot: "header" }, [
                    _vm._v("Afbeelding toevoegen uit bijlagen")
                  ]),
                  _c(
                    "template",
                    { slot: "content" },
                    [
                      _vm.isLoadingAttachments
                        ? _c("LoaderCard", {
                            attrs: { flat: "", type: "spinner--center" }
                          })
                        : _vm._e(),
                      !_vm.isLoadingAttachments
                        ? [
                            _c(
                              "div",
                              { staticClass: "overflow-auto elementPanel" },
                              [
                                !_vm.isLoading
                                  ? _c("Attachments", {
                                      ref: "attachment",
                                      attrs: {
                                        options: _vm.attachmentOptions,
                                        report: _vm.report,
                                        allowEdit: false,
                                        acceptedFileTypes:
                                          _vm.acceptedFileTypesForAddFromAttachment,
                                        hideActions: true
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "item.checkbox",
                                            fn: function(item) {
                                              return [
                                                _c("v-checkbox", {
                                                  staticClass:
                                                    "no-spacing-checkbox",
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.updateSelectedAttachments(
                                                        item,
                                                        $event
                                                      )
                                                    }
                                                  }
                                                })
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.file_name",
                                            fn: function(item) {
                                              return [
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: item.original,
                                                      target: "_blank"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "concatenateString"
                                                          )(item.file_name)
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.thumbnail",
                                            fn: function(item) {
                                              return [
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: item.original,
                                                      target: "_blank"
                                                    }
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticClass:
                                                        "bijlagen-thumbnail",
                                                      attrs: {
                                                        src: item.original,
                                                        alt: item.description
                                                      }
                                                    })
                                                  ]
                                                )
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.type",
                                            fn: function(item) {
                                              return [
                                                _vm._v(
                                                  " " + _vm._s(item.type) + " "
                                                )
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.created_at",
                                            fn: function(item) {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("dateFormatTime")(
                                                        item.created_at
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  ),
                  _c(
                    "template",
                    { slot: "actions" },
                    [
                      !_vm.isLoading
                        ? _c(
                            "v-layout",
                            {
                              attrs: { "justify-end": "", "align-center": "" }
                            },
                            [
                              _c(
                                "v-flex",
                                { attrs: { shrink: "" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "mr-3 fadedText link",
                                      on: { click: _vm.closeAddingAttachment }
                                    },
                                    [_vm._v("Annuleren")]
                                  ),
                                  _c(
                                    "MiButton",
                                    {
                                      attrs: {
                                        disabled:
                                          !_vm.selectedAttachments.length ||
                                          _vm.isLoadingAttachments,
                                        color: "primary",
                                        small: "true"
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.addAttachments($event)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.selectedAttachments.length > 1
                                              ? "Bijlagen toevoegen"
                                              : "Bijlage toevoegen"
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        : _vm._e(),
      _vm.isUploadingFile
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "750" },
              model: {
                value: _vm.isUploadingFile,
                callback: function($$v) {
                  _vm.isUploadingFile = $$v
                },
                expression: "isUploadingFile"
              }
            },
            [
              _c("v-card", [
                _c(
                  "div",
                  [
                    _c(
                      "v-card-title",
                      [
                        _c(
                          "v-layout",
                          {
                            staticClass: "elementPanel__header",
                            attrs: { row: "", wrap: "" }
                          },
                          [
                            _c(
                              "v-flex",
                              {
                                staticClass: "header__title",
                                attrs: { sm11: "" }
                              },
                              [_c("h2", [_vm._v("Media toevoegen")])]
                            ),
                            _c(
                              "v-flex",
                              {
                                staticClass: "header__close",
                                attrs: { sm1: "" }
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.closeUploadingFile($event)
                                      }
                                    }
                                  },
                                  [_vm._v("cancel")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-card-text",
                      [
                        _c(
                          "v-container",
                          { staticClass: "createDialog" },
                          [
                            _c(
                              "v-layout",
                              { attrs: { wrap: "" } },
                              [
                                _c("v-flex", { attrs: { xs12: "" } }, [
                                  _c("p", [
                                    _vm._v(" " + _vm._s(_vm.uploadText) + " ")
                                  ])
                                ]),
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "" } },
                                  [
                                    _c(
                                      "v-layout",
                                      {
                                        staticClass: "fileUpload__container",
                                        attrs: {
                                          "fill-height": "",
                                          "pa-1": "",
                                          wrap: ""
                                        }
                                      },
                                      [
                                        _c("MiFileUpload", {
                                          ref: "fileUpload",
                                          attrs: {
                                            filePath: _vm.uploadedFile,
                                            clearAfterUpload: false,
                                            acceptedFileTypes:
                                              _vm.acceptedFileTypes,
                                            multiple: true
                                          },
                                          on: {
                                            itemDropped: function($event) {
                                              return _vm.handleItemDropped(
                                                $event
                                              )
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.isDuplicatingDamage
        ? _c(
            "v-dialog",
            {
              attrs: { persistent: true, "max-width": "700px" },
              model: {
                value: _vm.isDuplicatingDamage,
                callback: function($$v) {
                  _vm.isDuplicatingDamage = $$v
                },
                expression: "isDuplicatingDamage"
              }
            },
            [
              _c("DuplicateDamageDialog", {
                attrs: { damage: _vm.currentSelectedDamage },
                on: { damageDuplicated: _vm.damageDuplicated },
                model: {
                  value: _vm.isDuplicatingDamage,
                  callback: function($$v) {
                    _vm.isDuplicatingDamage = $$v
                  },
                  expression: "isDuplicatingDamage"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.isImportingDamages
        ? _c("ImportDamagesDialog", {
            attrs: { report: _vm.report },
            on: {
              damagesImported: function($event) {
                return _vm.getDamages()
              }
            },
            model: {
              value: _vm.isImportingDamages,
              callback: function($$v) {
                _vm.isImportingDamages = $$v
              },
              expression: "isImportingDamages"
            }
          })
        : _vm._e(),
      _vm.transferDamage
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.transferDamage,
                callback: function($$v) {
                  _vm.transferDamage = $$v
                },
                expression: "transferDamage"
              }
            },
            [
              _c(
                "DefaultDialog",
                {
                  on: {
                    close: function($event) {
                      _vm.transferDamage = false
                      _vm.selectedDamage = null
                    }
                  }
                },
                [
                  _c("template", { slot: "header" }, [
                    _vm._v(
                      _vm._s(_vm.transferTitle) + " dupliceren naar een schade"
                    )
                  ]),
                  _c(
                    "template",
                    { slot: "content" },
                    [
                      _vm.isLoading
                        ? _c("LoaderCard", {
                            attrs: { flat: "", type: "spinner--center" }
                          })
                        : _vm._e(),
                      !_vm.isLoading
                        ? [
                            _c("v-autocomplete", {
                              attrs: {
                                items: _vm.nameParsedDamages,
                                "item-text": "name",
                                "item-value": "uuid",
                                "hide-details": "",
                                placeholder: "Zoek een schade",
                                label: "Schades"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "item",
                                    fn: function(data) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            class: {
                                              "tw-text-gray-500 tw-italic":
                                                data.item.is_room_without_damage
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(data.item.name) + " "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "selection",
                                    fn: function(data) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            class: {
                                              "tw-text-gray-500 tw-italic":
                                                data.item.is_room_without_damage
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(data.item.name) + " "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2508280508
                              ),
                              model: {
                                value: _vm.selectedDamage,
                                callback: function($$v) {
                                  _vm.selectedDamage = $$v
                                },
                                expression: "selectedDamage"
                              }
                            })
                          ]
                        : _vm._e()
                    ],
                    2
                  ),
                  _c(
                    "template",
                    { slot: "actions" },
                    [
                      !_vm.isLoading
                        ? _c(
                            "v-layout",
                            {
                              attrs: { "justify-end": "", "align-center": "" }
                            },
                            [
                              _c(
                                "v-flex",
                                { attrs: { shrink: "" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "mr-3 fadedText link",
                                      on: {
                                        click: function($event) {
                                          _vm.transferDamage = false
                                          _vm.selectedDamage = null
                                        }
                                      }
                                    },
                                    [_vm._v(" Annuleren ")]
                                  ),
                                  _c(
                                    "MiButton",
                                    {
                                      attrs: {
                                        disabled: !_vm.selectedDamage,
                                        color: "primary",
                                        small: "true"
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.transferToDamage()
                                        }
                                      }
                                    },
                                    [_vm._v(" Dupliceren ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        : _vm._e(),
      _vm.isAddingRepairSet
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "70vw" },
              model: {
                value: _vm.isAddingRepairSet,
                callback: function($$v) {
                  _vm.isAddingRepairSet = $$v
                },
                expression: "isAddingRepairSet"
              }
            },
            [
              _c("RepairSetDialog", {
                attrs: { report: _vm.report, damage: _vm.currentDamage },
                on: {
                  close: function($event) {
                    _vm.isAddingRepairSet = false
                  },
                  reload: function($event) {
                    return _vm.getRepairs()
                  }
                },
                model: {
                  value: _vm.isAddingRepairSet,
                  callback: function($$v) {
                    _vm.isAddingRepairSet = $$v
                  },
                  expression: "isAddingRepairSet"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.isCreatingRepair
        ? _c(
            "v-dialog",
            {
              attrs: { persistent: true, "max-width": "700px" },
              model: {
                value: _vm.isCreatingRepair,
                callback: function($$v) {
                  _vm.isCreatingRepair = $$v
                },
                expression: "isCreatingRepair"
              }
            },
            [
              _c("RepairDialog", {
                attrs: {
                  report: _vm.report,
                  damage: _vm.currentDamage,
                  repair: _vm.repair
                },
                on: {
                  closeDialog: _vm.closeCreatingRepair,
                  initRepairs: _vm.getRepairs
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.isEditingImage
        ? _c(
            "v-dialog",
            {
              attrs: { fullscreen: true },
              model: {
                value: _vm.isEditingImage,
                callback: function($$v) {
                  _vm.isEditingImage = $$v
                },
                expression: "isEditingImage"
              }
            },
            [
              _c("EditImageDialog", {
                attrs: {
                  currentDamage: _vm.currentDamage,
                  selectedMedia: _vm.selectedMedia
                },
                on: {
                  closeDialog: _vm.closeEditingImage,
                  postFile: function($event) {
                    return _vm.postFile($event, true)
                  },
                  mediaCreated: function($event) {
                    return _vm.getDamage(_vm.currentDamage)
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }