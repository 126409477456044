import { addClass, removeClass } from '@/support/Html';
import { AlertBar } from '@/support/AlertBar';
import { Answer } from '@/models/Answer';
import { Article } from '@/models/Article';
import { AttachmentOptions } from '@/components/attachments/Attachments';
import { AxiosError, AxiosResponse } from 'axios';
import { Component, Watch, ProvideReactive } from 'vue-property-decorator';
import { Damage } from '@/models/Damage';
import { DamageVisibility } from '@/components/damage-panel/DamagePanel';
import { debounce, indexOf } from 'lodash';
import { disableEditor } from '@/support/ReportEditor';
import { File as FileModel } from '@/models/File';
import { formatDate, sanitizeString } from '@/support/String';
import { GeneralKey, GeneralKeys } from '@/support/GeneralKeys';
import { Lock } from '@/support/Lock';
import { Media as MediaModel } from '@/models/Media';
import { Options } from '@/components/mi-dialog/MiDialog';
import { Question } from '@/models/Question';
import { RejectReason as RejectReasonModel } from '@/models/RejectReason';
import { Repair } from '@/models/Repair';
import { RepairType } from '@/models/RepairType';
import { Report as ReportModel, Media, Report, ReportIncludes } from '@/models/Report';
import { ReportStatus } from '@/support/ReportStatus';
import { Rpc } from '@/models/Rpc';
import { showHeaderbasedOnStatus } from '@/support/Warning';
import { spliceItems } from '@/support/Utilities';
import { Tab } from '@/views/Reports/Report';
import { userRoles } from '@/models/User';
import Aanzichtfotos from '@/views/Reports/Aanzichtfotos/Aanzichtfotos.vue';
import AddRepair from '@/components/add-repair/AddRepair.vue';
import draggable from 'vuedraggable';
import ErrorHandler from '@/support/ErrorHandler';
import HistoricalDamagePanel from '@/components/historical-damage-panel/HistoricalDamagePanel.vue';
import { HistoricalReport } from '@/models/HistoricalReport';
import IdleWarningDialog from '@/components/IdleWarningDialog';
import ImportDamagesDialog from '@/views/Editor/Expert/ImportDamages/ImportDamagesDialog.vue';
import LibraryArticle from '@/views/LibraryArticle/LibraryArticle.vue';
import MiFileUpload from '@/components/mi-file-upload/MiFileUpload';
import Plattegronden from '@/views/Reports/Plattegronden/Plattegronden.vue';
import Questionnaire from '@/components/Questionnaire/Questionnaire.vue';
import RepairSetDialog from '@/components/dialog/repair-set-dialog/RepairSetDialog.vue';
import DuplicateDamageDialog from '@/components/dialog/DuplicateDamageDialog/DuplicateDamageDialog.vue';
import SideDrawer from '@/components/SideDrawer.vue';
import Storage from '@/support/Storage';
import { DateTime } from 'luxon';
import DamageRemarks from './DamageRemarks/DamageRemarks.vue';

@Component<Expert>({
  components: {
    RepairSetDialog,
    draggable,
    HistoricalDamagePanel,
    ImportDamagesDialog,
    Questionnaire,
    AddRepair,
    LibraryArticle,
    SideDrawer,
    Plattegronden,
    Aanzichtfotos,
    DamageRemarks,
    DuplicateDamageDialog,
  },
})
export default class Expert extends IdleWarningDialog {
  // #region @Props
  /**
   * ie.
   * @Prop()
   * protected user!: User
   */
  // #endregion

  // #region @Refs
  /**
   * ie.
   * @Ref()
   * readonly anotherComponent!: AnotherComponent
   */
  // #endregion

  // #region Class properties
  /**
   * ie.
   * protected isLoading = true;
   * * protected company: Company | null = null;
   */

  public $pageTitle = 'Deskundige';

  // LOCK
  protected lock: Lock = new Lock();

  protected lockKey = '';

  protected toggleInReportTimerOn = false;

  protected ReportStatus: typeof ReportStatus = ReportStatus;

  protected report: ReportModel = new ReportModel();

  protected reportClone: ReportModel | null = null;

  @ProvideReactive() // Injected at ImageRecognitionQuestion.ts
  protected currentDamage: Damage | null = null;

  protected currentDamageRepairs: ModifiedRepair[] | null = null;

  @ProvideReactive() // Injected at ImageRecognitionQuestion.ts
  protected currentDamageMedia: FileModel[] | null = null;

  protected currentSelectedDamage: Damage | null = null;

  protected damages: Damage[] = [];

  protected historicalReports: HistoricalReport[] = [];

  protected isLoading = true;

  protected isLoadingDamage = false;

  protected isLoadingArticle = true;

  protected isLoadingRepairs = false;

  protected isLoadingDamages = true;

  protected isLoadingQuestions = false;

  protected fileCounter = 0;

  protected expertTabContainerHeight = 0;

  protected currentDamageContainerHeight = 0;

  protected tabHeight = 48;

  protected userRoles = userRoles;

  protected showDrawer = false;

  protected selectedArticle: Article | null = null;

  protected reportQuestions: Question[] = [];

  protected reportAnswers: Answer[] = [];

  @ProvideReactive() // Injected at ImageRecognitionQuestion.ts
  protected damageQuestions: Question[] = [];

  protected zelfopnameQuestions: Question[] = [];

  protected damageAnswers: Answer[] = [];

  protected repairTypes: RepairType[] = [];

  // approval bar
  protected approvalDialogOpen = false;

  protected approval = '';

  protected status = '';

  protected title = '';

  protected isRejected = false;

  protected isObjection = false;

  protected isStuwmeer = false;

  protected isZienswijze = false;

  protected isOpname = false;

  protected isOpnameRejected = false;

  protected hasRejectButton = false;

  protected isValidDate = true;

  protected transferTitle = '';

  protected transferType = '';

  protected transferDamage = false;

  protected selectedDamage: string | null = null;

  protected isAddingRepairSet = false;

  protected uploadQueue: File[] = [];

  protected postTimeOut: any | null = null;

  protected undoChanged = 0;

  protected isCropping = false;

  protected editorHeight = '';

  protected currentQuestion = '';

  protected currentAnswer = '';

  protected activeTab = 'tab-questionair';

  protected activeTabSub = 'tab-report';

  protected currentStep = 1;

  protected newCurrentStep = 1;

  protected formSections: any = [1];

  protected selectedMedia: Media | null = null;

  protected isUploadingFile = false;

  protected uploadedFile = '';

  protected uploaded = false;

  protected acceptedFileTypes: string[] = ['image/png', 'image/jpeg', 'image/svg+xml'];

  protected acceptedFileTypesForAddFromAttachment: string[] = ['image/bmp', 'image/gif', 'image/jpg', 'image/jpeg', 'image/png'];

  protected selectedRepair: Repair | null = null;

  protected repair: Repair = new Repair();

  protected isCreatingRepair = false;

  protected isEditingImage = false;

  protected isDuplicatingDamage = false;

  protected isImportingDamages = false;

  protected sections: string[] = ['1', '2', '3', '4', '5', '6', '7', '8'];

  protected newSections: SectionItem[] = [
    {
      name: 'Werkvoorbereiding',
      step: 1,
      sections: [100],
    },
    {
      name: 'Planning',
      step: 2,
      sections: [110],
    },
    {
      name: 'Opname',
      step: 3,
      sections: [120],
    },
  ];

  protected visibility: any = {
    edit: true,
    duplicate: false,
  };

  protected mask: Mask = {
    time: '##:##',
  };

  protected isLoadingEditor = true;

  protected currentEditorProp: any = {};

  protected isAllowedToViewDialog = false;

  // Repair exception
  protected exceptionVisible = false;

  protected damageExceptions: DamageException[] = [
    {
      key: 'verkleuring',
      name: 'Verkleuring / vlekvorming',
    },
    {
      key: 'verfwerk',
      name: 'Bladderen van verfwerk',
    },
    {
      key: 'loslaten',
      name: 'Loslaten / onthechten verf- en kitwerk',
    },
    {
      key: 'veroudering',
      name: 'Veroudering / verwering',
    },
    {
      key: 'krimpscheur',
      name: 'Krimpscheur in materialen',
    },
    {
      key: 'overig',
      name: 'Overig',
    },
  ];

  protected reportRejectReasons: RejectReasonModel[] = [];

  protected damageRejectReasons: RejectReasonModel[] = [];

  // START: Add attachment
  protected isAddingAttachment = false;

  protected isLoadingAttachments = false;

  protected selectedAttachments: Media[] = [];
  // END: Add attachment

  protected damageVisibility: DamageVisibility = {
    validations: true,
    manager: true,
    edit: true,
    rejectReasons: true,
    internal: true,
    reject: true,
    approve: true,
  };

  protected generalKeys: GeneralKey = GeneralKeys;

  protected debounceExplanation = debounce(this.updateExplanation, 1500);

  protected debounceReasoning = debounce(this.updateReasoning, 1500);

  protected scrollHeight = 0;
  // #endregion

  // #region Lifecycle Hooks / Init

  protected created() {
    window.onbeforeunload = () => {
      this.lock.unlock(this.lockKey);
    };
  }

  public mounted() {
    // LOCK
    this.createLock();

    this.$root.$on('dateFieldValid', this.setValidDate);
    this.repair.form = 'calculation';
    this.initialize();
    this.getStorageValues();

    const pageContainer = document.querySelectorAll('.pageContainer')[0];
    addClass(pageContainer, 'pageContainer--tablet');
    addClass(pageContainer, 'pageContainer--fullwidth');
    addClass(pageContainer, 'pageContainer--mb-actionbar');
  }

  protected destroyed() {
    this.lock.unlock(this.lockKey);
    Storage.delete('expert_activeTab');
    Storage.delete('expert_activeTabSub');
    Storage.delete('expert_currentStep');
    Storage.delete('expert_new_currentStep');
    Storage.delete('expert_currentDamageId');

    const body = <HTMLElement>document.querySelector('body');
    removeClass(body, 'bodyNoScroll');
  }

  // #endregion

  // #region Class methods
  protected setAlert() {
    if (this.lock.user) {
      this.$store.state.alert.message = AlertBar.setIsLockedMessage(this.lock.user);
      this.$store.state.alert.show = true;
    } else {
      this.$store.state.alert.show = false;
    }
  }

  protected addCurrentDamageToStorage(damage: Damage) {
    if (damage && damage.uuid) {
      Storage.set('expert_currentDamageId', damage.uuid);
    }
  }

  protected beforeDestroy() {
    const pageContainer = document.querySelectorAll('.pageContainer')[0];
    removeClass(pageContainer, 'pageContainer--tablet');
    removeClass(pageContainer, 'pageContainer--fullwidth');
    removeClass(pageContainer, 'pageContainer--mb-actionbar');
  }

  protected getStorageValues() {
    if (Storage.get('expert_activeTab')) {
      this.activeTab = `${Storage.get('expert_activeTab')}`;
    }

    if (Storage.get('expert_activeTabSub')) {
      this.activeTabSub = `${Storage.get('expert_activeTabSub')}`;
    }

    if (Storage.get('expert_currentStep')) {
      this.currentStep = Number(Storage.get('expert_currentStep'));
    }

    if (Storage.get('expert_new_currentStep')) {
      this.newCurrentStep = Number(Storage.get('expert_new_currentStep'));
    }
  }

  protected initBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated', {
      crumb: [{ name: 'Alle rapporten', path: '/reports' }, { name: this.report.case_number, path: `/reports/${this.report.uuid}` }, { name: 'Deskundige' }],
    });
  }

  // protected beforeDestroyed() {
  //   this.lock.unlock(this.lockKey);
  // }

  protected handlePanelChanged(panels: boolean[]) {
    Storage.set('manager_panels', JSON.stringify(panels));
  }

  protected reportAnswerSaved(answer: Answer) {
    this.getReport();
  }

  protected async reloadDamageAnswers() {
    if (! this.currentDamage) {
      return;
    }

    const element = document.getElementById('element-panel-damage-questionnaire');
    this.scrollHeight = element?.scrollTop || 0;

    await this.getDamage(this.currentDamage);
  }

  protected async reloadReportAnswers() {
    const element = document.getElementById('element-panel-report-questionnaire');
    this.scrollHeight = element?.scrollTop || 0;
    this.isLoadingQuestions = true;
    await this.getReportAnswers();
    await this.getReport();
    this.isLoadingQuestions = false;

    this.$nextTick(() => {
      if (element) {
        element.scrollTo(0, this.scrollHeight);
        this.scrollHeight = 0;
      }
    });
  }

  protected damageAnswerSaved(damage: Damage) {
    const index = this.damages.findIndex((currentDamage: Damage) => currentDamage.uuid === damage.uuid);

    if (index < 0) {
      return;
    }

    this.damages[index].name = damage.name;
    this.damages[index].validations = damage.validations;
    this.damages[index].is_room_without_damage = damage.is_room_without_damage;
    this.damages[index].casuality_has_deviated = damage.casuality_has_deviated;
    this.damages[index].casuality_anomaly_reason = damage.casuality_anomaly_reason;
    this.damages[index].casuality_anomaly_explanation = damage.casuality_anomaly_explanation;
    if (! this.currentDamage) {
      return;
    }

    this.currentDamage.validations = damage.validations;
    this.currentDamage.answers = damage.answers;
    this.currentDamage.classification = damage.classification;
    this.currentDamage.casuality_has_deviated = damage.casuality_has_deviated;
    this.currentDamage.casuality_anomaly_reason = damage.casuality_anomaly_reason;
    this.currentDamage.casuality_anomaly_explanation = damage.casuality_anomaly_explanation;
  }

  protected showSteps(section: number) {
    if (! this.report.isRegulierReport && ! this.report.isUsingRegulier2021Questions) {
      return section !== 6;
    }
    return section !== 6 && section !== 4;
  }

  protected showApprovalButtons() {
    this.isRejected = this.report.status === 'rejected';
    if (this.report.type) {
      if (this.isMutatieWoning) {
        return;
      }

      this.isObjection = this.report.isBezwaarReport;
      this.isObjection = this.report.isBezwaarReport;
      this.isStuwmeer = this.report.isStuwmeerRegelingReport;
      this.isStuwmeer = this.report.isStuwmeerRegelingReport;
      this.isOpname = this.report.status === 'opname_in_progress';
      this.isOpnameRejected = this.report.status === 'opname_rejected';

      this.hasRejectButton = this.report.status === 'in_progress' || this.report.status === 'rejected';
    }

    if (this.report.status) {
      this.isZienswijze = this.report.isZienswijzeReport;
      this.isZienswijze = this.report.isZienswijzeReport;
    }
  }

  protected setValidDate(isValid: boolean) {
    this.isValidDate = isValid;
  }

  protected toggleApproveDialog(status: string, title: string, approval: string) {
    this.status = status;
    this.approval = approval;
    this.title = title;
    this.approvalDialogOpen = true;
  }

  protected hasDamageStatusChangedOrPending() {
    return this.currentDamage && (this.currentDamage.status === 'changed' || this.currentDamage.status === 'pending');
  }

  protected addRepair(repair: Repair) {
    this.currentDamageRepairs?.push(repair);
  }

  protected compare(a: any, b: any) {
    let comparison = 0;

    if (a.sort_order === undefined || b.sort_order === undefined) {
      return 0;
    }

    if (a.sort_order > b.sort_order) {
      comparison = 1;
    } else if (a.sort_order < b.sort_order) {
      comparison = - 1;
    }
    return comparison;
  }

  protected showHeaderError(validations: any) {
    return showHeaderbasedOnStatus(validations, 'error');
  }

  protected showHeaderWarning(validations: any) {
    return showHeaderbasedOnStatus(validations, 'warning');
  }

  protected onClickDeleteDamage(damage: Damage) {
    this.currentSelectedDamage = damage;
    this.$store.dispatch('openDialog', this.dialogOptionsDelete);
  }

  protected onClickDuplicateDamage(damage: Damage) {
    this.currentSelectedDamage = damage;
    this.isDuplicatingDamage = true;
  }

  protected calculateDamageTotals() {
    if (! this.currentDamage || ! this.currentDamage.repairs) {
      return;
    }
    let total = 0;
    this.currentDamage.repairs.forEach((repair: any) => {
      total += repair.total_incl_vat;
    });
    this.currentDamage.totalCost = total;
    total = 0;
  }

  protected editRepair(repair: Repair) {
    this.repair = repair;
    this.isCreatingRepair = true;
  }

  protected deleteRepair(repair: Repair) {
    this.selectedRepair = repair;
    this.$store.dispatch('openDialog', this.dialogOptionsDeleteRepair);
  }

  protected closeCreatingRepair() {
    this.repair = new Repair();
    if (this.currentDamage) {
      this.getDamage(this.currentDamage);
    }
    this.isCreatingRepair = false;
  }

  protected closeEditingImage() {
    this.isEditingImage = false;
  }

  protected toggleVisibilityMedia(media: Media) {
    if (! media.uuid || this.toggleInReportTimerOn) {
      return;
    }
    this.toggleInReportTimerOn = true;

    media.show_in_report = media.show_in_report !== '0' ? '0' : '1';

    const payload = { show_in_report: media.show_in_report };
    new MediaModel()
      .toggleVisibilityMedia(media.uuid, payload)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      })
      .then(() => {
        this.toggleInReportTimerOn = false;
      });
  }

  protected editMedia(media: Media) {
    this.selectedMedia = media;
    this.isEditingImage = true;
  }

  protected deleteMedia(media: Media) {
    this.selectedMedia = media;
    this.$store.dispatch('openDialog', this.dialogOptionsDeleteImage);
  }

  protected handleItemDropped(file: File) {
    if (! file) {
      return;
    }

    this.uploadQueue.push(file);
  }

  protected closeUploadingFile() {
    this.uploaded = false;
    this.isUploadingFile = false;
    this.fileCounter = 0;
  }

  protected changeStep(direction: string) {
    if (this.report.isUsingRegulier2021Questions) {
      if (direction === 'next') this.newCurrentStep ++;
      if (direction === 'previous') this.newCurrentStep --;
      return;
    }

    if (direction === 'next') {
      if (this.currentStep === 5) {
        this.currentStep = 7;
        return;
      }
      if (this.currentStep === 3) {
        this.currentStep = this.report.isRegulierReport ? 5 : 4;
        return;
      }
      this.currentStep ++;
    }
    if (direction === 'previous') {
      if (this.currentStep === 7) {
        this.currentStep = 5;
        return;
      }
      if (this.currentStep === 5) {
        this.currentStep = this.report.isRegulierReport ? 3 : 4;
        return;
      }
      this.currentStep --;
    }
  }

  protected previewPdf() {
    window.open(this.report.links.preview);
  }

  protected hasRejectedInfo() {
    return (this.report.reject_reasons && this.report.reject_reasons.length) || (this.report.reject_comment && this.report.reject_comment.length);
  }

  protected hasRejectedDamageInfo() {
    return (
      this.report
      && this.currentDamage
      && (this.currentDamage.reject_reasons?.length
        || this.currentDamage.reject_comment?.length
        || (this.currentDamage.internal_reject_comment && this.currentDamage.internal_reject_comment.length))
    );
  }

  protected openConfirmBulkUpdate(question: string, answer: string) {
    this.currentQuestion = question;
    this.currentAnswer = answer;
    this.$store.dispatch('openDialog', this.dialogOptionsBulkUpdateDamages);
  }

  protected openTransferDialog(title: string, type: string) {
    this.transferType = type;
    this.transferTitle = title;
    this.transferDamage = true;
  }

  protected get nameParsedDamages(): Damage[] {
    return this.damages.map((damage: Damage, index: number) => {
      damage.name = Damage.createDamageName(damage, index, this.damages);
      return damage;
    });
  }

  protected formatDateSeconds(date: string) {
    return formatDate(date, 'dd-LL-yyyy HH:mm');
  }

  // START: Add attachment
  protected closeAddingAttachment(): void {
    this.isAddingAttachment = false;
    this.selectedAttachments = [];
  }

  protected updateSelectedAttachments(media: Media, value: boolean): void {
    if (value) {
      this.selectedAttachments.push(media);
    } else {
      this.selectedAttachments = this.selectedAttachments.filter((item: Media): boolean => media.uuid !== item.uuid);
    }
  }

  protected createDamageName(damage: Damage, index: number): string {
    return Damage.createDamageName(damage, index, this.damages);
  }

  // #endregion

  // #region Async methods
  /**
   * ie.
   * protected async fetchUserCompany(): Promise<void> {
   *  this.company = await new Company().filter({user: this.user.id}).all();
   * }
   */

  protected async updateReasoning() {
    const payload = {
      reject_reasons: this.report.reject_reasons?.map((rejectReason: RejectReasonModel) => rejectReason.id),
    };

    await this.report.update(payload).catch((error: AxiosError) => {
      ErrorHandler.network(error);
    });
  }

  protected async updateRepairQuantity(repair: Repair, index: number) {
    if (! this.currentDamage || ! repair.type || ! this.currentDamageRepairs) {
      return;
    }

    const newRepair = await new Repair(repair)
      .update({
        quantity: repair.quantity,
        damage: this.currentDamage.uuid,
        type: repair.type.uuid,
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    if (! this.currentDamageRepairs || ! newRepair) {
      return;
    }
    this.$set(this.currentDamageRepairs, index, newRepair);

    this.calculateDamageTotals();
  }

  protected sanitizeString(value: string): string {
    return sanitizeString(value, true);
  }

  protected async updateExplanation() {
    const payload = {
      internal_reject_comment: this.report.internal_reject_comment,
    };

    await this.report.update(payload).catch((error: AxiosError) => {
      ErrorHandler.network(error);
    });
  }

  protected async pingReport() {
    if (! this.report || ! this.report.uuid) {
      return;
    }

    await this.report.pingReport(this.report.uuid).catch((error: AxiosError) => {
      ErrorHandler.network(error);
    });
  }

  protected async getDamage(currentDamage: Damage) {
    if (! currentDamage) {
      return;
    }

    this.isLoadingDamage = true;
    const damage: Damage = await new Damage()
      .include(['validations', 'expert_remark', 'anomalies', 'service'])
      .find(currentDamage.uuid)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        this.isLoadingDamage = false;
      });

    if (damage.exception_reason && damage.exception_reason.length) {
      this.exceptionVisible = true;
    } else {
      this.exceptionVisible = false;
    }

    this.$set(this, 'currentDamage', damage);
    this.currentDamageRepairs = (damage.repairs as ModifiedRepair[]).sort(this.compare);
    this.currentDamageMedia = (damage.media as FileModel[]).sort(this.compare);

    const foundDamage = this.damages.find((newDamage: Damage) => currentDamage.uuid === newDamage.uuid);

    if (foundDamage) {
      foundDamage.validations = damage.validations;
    }

    this.$nextTick(() => {
      // Sets the height of the damage container correct based on the height of the reject comment container
      const damageStatusCommentContainer = document.querySelectorAll('#damage-status-comment-container')[0];
      this.currentDamageContainerHeight = damageStatusCommentContainer ? damageStatusCommentContainer.clientHeight + 20 + this.tabHeight : this.tabHeight;

      // Scroll to the last position
      const element = document.getElementById('element-panel-damage-questionnaire');
      if (element) {
        element.scrollTo(0, this.scrollHeight);
        this.scrollHeight = 0;
      }
    });

    this.calculateDamageTotals();

    this.isLoadingDamage = false;
  }

  protected async updateReport() {
    await this.report.update({}).catch((error: AxiosError) => {
      ErrorHandler.network(error);
    });
  }

  protected async fetchHistoricalReports(): Promise<void> {
    try {
      this.historicalReports = await new HistoricalReport()
        .filter({ report: this.report.uuid }).all();
    } catch (error) {
      //
    }
  }

  protected async postFiles() {
    if (! this.currentDamage || ! this.uploadQueue.length) {
      return;
    }

    const response = await new Damage()
      .mediaEndpoint(this.currentDamage)
      .create({ file: this.uploadQueue[0] })
      .catch((error: AxiosError) => {
        const fileUploadComponent = this.$refs.fileUpload as MiFileUpload;
        fileUploadComponent.uploadedMultipleFailed(this.fileCounter);
      });

    const queueCopy = [...[], ...this.uploadQueue];
    queueCopy.shift();
    this.uploadQueue = queueCopy;

    const fileUploadComponent = this.$refs.fileUpload as MiFileUpload;
    fileUploadComponent.uploadedMultiple(this.fileCounter);

    if (this.uploadQueue.length <= 0) {
      this.fileCounter = 0;
      this.getDamage(this.currentDamage as any);
    } else {
      setTimeout(() => {
        this.fileCounter += 1;
        this.postFiles();
      }, 500);
    }
  }

  protected async postFile(object: any, editing = false) {
    if (! this.currentDamage) {
      return;
    }

    const payload = {
      file: object.file,
      original_media: object.media?.uuid || '',
    };

    const response = await new Damage()
      .mediaEndpoint(this.currentDamage)
      .create(payload)
      .catch((error: AxiosError) => {
        if (editing) {
          this.isEditingImage = false;
        } else {
          const fileUploadComponent = this.$refs.fileUpload as MiFileUpload;
          if (fileUploadComponent) {
            fileUploadComponent.uploadedMultipleFailed(this.fileCounter);
          }
        }
      });

    if (editing) {
      this.isEditingImage = false;
      this.isLoading = false;
    } else {
      const fileUploadComponent = this.$refs.fileUpload as MiFileUpload;
      if (fileUploadComponent) {
        fileUploadComponent.uploadedMultiple(this.fileCounter);
        this.fileCounter += 1;
      }
    }

    if (! this.currentDamage) {
      return;
    }
    this.getDamage(this.currentDamage);
  }

  protected async updateDamageRepairOrder() {
    if (! this.currentDamageRepairs) {
      return;
    }

    const repairIds: string[] = this.currentDamageRepairs.map((repair: Repair) => repair.uuid as string);

    await new Repair().sortRepairs(repairIds).catch((error: AxiosError) => {
      ErrorHandler.network(error);
    });
  }

  protected async updateDamageOrder() {
    if (! this.damages) {
      return;
    }

    const damageIds: string[] = this.damages.map((damage: Damage) => damage.uuid as string);

    await new Damage().sortDamages(damageIds).catch((error: AxiosError) => {
      ErrorHandler.network(error);
    });
  }

  protected async updateDamageMediaOrder() {
    if (! this.currentDamageMedia) {
      return;
    }

    const fileIds: string[] = this.currentDamageMedia.map((file: FileModel) => file.uuid as string);

    await new FileModel().sortFiles(fileIds).catch((error: AxiosError) => {
      ErrorHandler.network(error);
    });
  }

  protected async updateDamageException() {
    if (! this.currentDamage) {
      return;
    }

    if (this.currentDamage.exception_reason && this.currentDamage.exception_reason === 'overig') {
      if (! this.currentDamage.exception_comment || ! this.currentDamage.exception_comment.length) {
        return;
      }
    }

    const damage = await this.currentDamage
      .update({
        exception_reason: this.currentDamage.exception_reason ? this.currentDamage.exception_reason : '',
        exception_comment: this.currentDamage.exception_reason ? this.currentDamage.exception_comment : '',
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    this.getDamage(damage);
  }

  protected async bulkUpdateAnswer() {
    if (! this.currentQuestion || ! this.currentAnswer) {
      return;
    }

    const payload: { [key: string]: any } = {
      signature: 'question:mass-answer',
      body: {
        report: this.report.uuid,
        question: this.currentQuestion,
        answer: this.currentAnswer,
      },
    };

    await new Rpc().rpcPost(payload).then(() => {
      this.$store.dispatch('openDialog', this.dialogOptionsSuccessBulkUpdateDamages);
    });
  }

  protected async createLock() {
    this.lockKey = `report:${this.$route.params.id}:editor:expert`;
    await this.lock.checkLock(this.lockKey);
    await this.lock.initLock(this.lockKey);
    this.setAlert();
  }

  protected async initialize() {
    this.isLoading = true;

    await this.getReport();

    if (this.report.isUsingRegulier2021Questions) {
      const currentSection = this.newSections.filter((section) => section.step === this.newCurrentStep);
      this.formSections = currentSection[0].sections;
      this.newCurrentStep = 3;
    }
    if (! this.isAllowedToView) {
      return this.$store.dispatch('openDialog', this.redirectWithAlert);
    }

    this.showApprovalButtons();

    this.getDamages();
    this.getDamageQuestions();
    this.getZelfopnameQuestions();
    this.pingReport();

    await Promise.all([
      this.getReportQuestions(),
      this.getReportAnswers(),
      this.getRepairTypes(),
      this.getRejectReasons(),
      this.fetchHistoricalReports(),
    ]);

    this.initBreadcrumb();

    // Sets the height of the report container correct based on the height of the reject comment container
    this.$nextTick(() => {
      const reportStatusCommentContainer = document.querySelectorAll('#report-status-comment-container')[0];
      this.expertTabContainerHeight = reportStatusCommentContainer ? reportStatusCommentContainer.clientHeight + 20 + this.tabHeight : this.tabHeight;
    });

    this.isLoading = false;
  }

  protected async getReport() {
    this.report = await new ReportModel()
      .include([
        ReportIncludes.APPLICANT,
        ReportIncludes.VALIDATIONS,
        ReportIncludes.REJECT_REASONS,
        ReportIncludes.MEDIA,
        ReportIncludes.ANSWERS,
        ReportIncludes.OBJECTION,
      ])
      .find(this.$route.params.id)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async getRejectReasons() {
    if (! this.hasRejectButton) {
      return;
    }

    new RejectReasonModel()
      .filter({ type: 'woco' })
      .all()
      .then((response: any) => {
        this.reportRejectReasons = response;
      });
  }

  protected async getReportQuestions() {
    if (! this.report || ! this.report.uuid) {
      return;
    }

    const payload = {
      form_types: ['manager', 'tcmg', 'expert', 'pre_controller'],
      sections: this.formSections,
      report_type: this.report.type?.uuid,
      report: this.report.uuid,
    };

    this.reportQuestions = await new Question()
      .filter(payload)
      .limit(300)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async getRepairTypes() {
    if (! this.report || ! this.report.uuid) {
      return;
    }

    const payload = {
      section: '',
      calculation_model: this.report.calculation_model ? this.report.calculation_model.uuid : '',
    };

    this.repairTypes = await new RepairType()
      .filter(payload)
      .limit(500)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async getReportAnswers() {
    if (! this.report || ! this.report.uuid) {
      return;
    }

    const payload = {
      report_type: this.report.type?.uuid,
      report: this.report.uuid,
    };

    this.reportAnswers = await new Answer()
      .filter(payload)
      .limit(300)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async getRepairs() {
    if (! this.currentDamage) {
      return;
    }

    this.isLoadingRepairs = true;

    new Damage()
      .find(this.currentDamage.uuid)
      .then((damage: Damage) => {
        this.currentDamageRepairs = (damage.repairs as ModifiedRepair[]).sort(this.compare);
        this.currentDamage = damage;
        this.isLoadingRepairs = false;
        this.calculateDamageTotals();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async getDamages() {
    this.isLoadingDamages = true;

    this.damages = await new Damage()
      .refactor()
      .include(['validations', 'reject_reasons'])
      .filter({ report: this.report.uuid })
      .limit(1000)
      .all();

    this.isLoadingDamages = false;

    if (Storage.get('expert_currentDamageId')) {
      if (! this.damages) {
        return;
      }

      const foundDamage = this.damages.find((damage: Damage) => Storage.get('expert_currentDamageId') === damage.uuid);

      if (! foundDamage) {
        return;
      }
      this.getDamage(foundDamage);
    }
  }

  protected async getDamageQuestions() {
    if (! this.report || ! this.report.uuid) {
      return;
    }

    const payload = {
      form_types: ['manager', 'tcmg', 'expert', 'pre_controller'],
      sections: this.report.isUsingRegulier2021Questions ? 121 : 51,
      report_type: this.report.type?.uuid,
      report: this.report.uuid,
    };

    this.damageQuestions = await new Question()
      .filter(payload)
      .limit(300)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async getZelfopnameQuestions() {
    if (! this.report || ! this.report.uuid) {
      return;
    }

    const payload = {
      form_types: ['manager', 'tcmg', 'expert', 'pre_controller'],
      sections: [53, 54],
      report_type: this.report.type?.uuid,
      report: this.report.uuid,
    };

    this.zelfopnameQuestions = await new Question()
      .filter(payload)
      .limit(300)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async onClickAddDamage() {
    const damage = await new Damage()
      .include(['validations', 'reject_reasons'])
      .create({ report: this.$route.params.id })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    if (damage) {
      this.damages.push(damage);
    }
  }

  protected async damageDuplicated(damage: Damage) {
    if (! this.currentSelectedDamage || ! damage) {
      return;
    }

    const index = this.damages.findIndex((damage) => damage.uuid === this.currentSelectedDamage?.uuid);
    this.damages.splice(index + 1, 0, damage);
    this.currentSelectedDamage = null;
  }

  protected async transferToDamage() {
    if (! this.selectedDamage || ! this.currentDamage) {
      return;
    }
    const payload: { [key: string]: boolean | string[] } = {};

    payload.media = this.transferType === 'media';
    payload.repairs = this.transferType === 'repair';
    payload.targets = [this.selectedDamage];
    this.isLoading = true;

    await new Damage(this.currentDamage).transferDamage().create(payload);

    this.isLoading = false;
    this.selectedDamage = null;
    this.transferDamage = false;
  }

  protected async onClickInfo(question: Question) {
    this.showDrawer = true;
    this.isLoadingArticle = true;
    const article = await new Article()
      .include(['media', 'default_answers', 'repair_types'])
      .find(question.library_article_id)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    if (article) {
      this.isLoadingArticle = false;
      this.selectedArticle = article;
    }
  }

  protected async addAttachments(): Promise<void> {
    this.isLoadingAttachments = true;
    if (! this.selectedAttachments.length) {
      return;
    }
    for (let i = 0; i < this.selectedAttachments.length; i ++) {
      // eslint-disable-next-line no-await-in-loop
      await this.addAttachment(this.selectedAttachments[i]);
    }
    await this.getDamages();
    this.closeAddingAttachment();
    this.isLoadingAttachments = false;
    this.selectedAttachments = [];
  }

  protected async addAttachment(media: Media): Promise<void> {
    const rpcPayloadBody: { media: string; target: string } = {
      media: `${media.uuid}`,
      target: `${this.currentDamage?.uuid}`,
    };

    const rpc: Rpc | null = await new Rpc()
      .rpcPost({
        signature: 'report:copy-media-to-damage',
        body: rpcPayloadBody,
      })
      .catch((error: AxiosError): null => {
        ErrorHandler.network(error);
        return null;
      });
  }

  // #endregion

  // #region Getters & Setters

  protected get isLockedByUser(): boolean {
    return this.lock.isLockedByUser;
  }

  protected get isAllowedToView(): boolean {
    if (this.$store.state.isServiceOrganization) {
      return false;
    }

    if (! this.report) {
      return true;
    }

    return disableEditor('deskundige', this.report, this.$store.state.Auth);
  }

  protected get redirectWithAlert(): Options {
    return {
      title: 'Niet beschikbaar',
      text: 'Deze pagina is op dit moment niet beschikbaar',
      type: 'error',
      buttons: {
        confirm: {
          text: 'Terug naar dossier',
          action: () => {
            this.$router.push(`/reports/${this.report.uuid}`);
          },
        },
      },
    };
  }

  protected get panelsFromStorage() {
    return JSON.parse(`${Storage.get('manager_panels')}`);
  }

  protected get uploadText() {
    if (! this.uploaded) {
      return 'Upload een bestand in:';
    }

    return 'Upload nog een bestand in:';
  }

  protected get dialogOptionsDeleteRepair(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: 'Weet je zeker dat je deze reparatie wilt verwijderen?',
      type: 'warning',
      buttons: {
        confirm: {
          text: this.$t('dialogOptions.button.delete').toString(),
          color: 'warning',
          action: () => {
            if (! this.currentDamage || ! this.selectedRepair) {
              return;
            }

            new Repair()
              .removeRepair((this.selectedRepair as any).uuid)
              .then((response: AxiosResponse) => {
                if (! this.currentDamage) {
                  return;
                }
                this.getDamage(this.currentDamage);
              })
              .catch((error: AxiosError) => {
                ErrorHandler.network(error);
              });
          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
          action: () => {
            //
          },
        },
      },
    };
  }

  protected get dialogOptionsDeleteImage(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: 'Weet je zeker dat je deze afbeelding wilt verwijderen?',
      type: 'warning',
      buttons: {
        confirm: {
          text: this.$t('dialogOptions.button.delete').toString(),
          color: 'warning',
          action: async () => {
            if (! this.currentDamage || ! this.selectedMedia) {
              return;
            }

            const media = await new Damage(this.selectedMedia)
              .mediaEndpoint(this.currentDamage)
              .delete()
              .catch((error: AxiosError) => {
                ErrorHandler.network(error);
              });

            if (media && this.currentDamageMedia) {
              this.currentDamageMedia = spliceItems(this.currentDamageMedia, this.selectedMedia, 'uuid');
            }
          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
          action: () => {
            //
          },
        },
      },
    };
  }

  protected get dialogOptionsDelete(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: 'Weet je zeker dat je deze schade wilt verwijderen?',
      type: 'warning',
      buttons: {
        confirm: {
          text: this.$t('dialogOptions.button.delete').toString(),
          color: 'warning',
          action: async () => {
            if (! this.currentSelectedDamage) {
              return;
            }

            const damage = await new Damage(this.currentSelectedDamage).delete().catch((error: AxiosError) => {
              ErrorHandler.network(error);
            });

            if (damage && this.currentSelectedDamage) {
              this.damages = spliceItems(this.damages, this.currentSelectedDamage, 'uuid');
              this.currentDamage = this.currentSelectedDamage.uuid === this.currentDamage?.uuid ? null : this.currentDamage;
              this.currentSelectedDamage = null;
            }
          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
          action: () => {
            //
          },
        },
      },
    };
  }

  protected get dragOptions() {
    return {
      animation: 200,
      disabled: false,
      ghostClass: 'ghost',
    };
  }

  protected get hasValidations() {
    if (! this.currentDamage || ! this.currentDamage.validations) {
      return false;
    }

    const validations = Object.keys(this.currentDamage?.validations).find((validation: string) => this.currentDamage?.validations[validation] !== 'ok');

    return validations || false;
  }

  protected get dialogOptionsBulkUpdateDamages(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: 'Weet je zeker dat je alle schades wilt aanpassen?',
      type: 'warning',
      buttons: {
        confirm: {
          text: 'Ja',
          color: 'success',
          action: () => {
            this.bulkUpdateAnswer();
          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
          action: () => {
            //
          },
        },
      },
    };
  }

  protected get dialogOptionsSuccessBulkUpdateDamages(): Options {
    return {
      title: 'Succes',
      text: 'Het aanpassen van alle schades is gelukt.',
      type: 'success',
      buttons: {
        confirm: {
          text: 'ok',
        },
      },
    };
  }

  protected get isMutatieWoning(): boolean {
    return this.report.isMutatieWoningReport;
  }

  protected get currentDamageIndex() {
    if (! this.currentDamage) {
      return '';
    }

    const foundIndex = this.damages.findIndex((damage: Damage) => this.currentDamage?.uuid === damage.uuid);

    return foundIndex >= 0 ? foundIndex + 1 : '';
  }

  protected get tabs(): Tab[] {
    return [
      {
        name: 'Vragenlijst',
        key: 'tab-questionair',
        href: '#tab-questionair',
        visible: () => true,
      },
      {
        name: 'Schades',
        key: 'tab-schades',
        href: '#tab-schades',
        visible: () => true,
      },
      {
        name: 'Tools',
        key: 'tab-tools',
        href: '#tab-tools',
        visible: () => true,
      },
      {
        name: 'Bijlagen',
        key: 'tab-bijlagen',
        href: '#tab-bijlagen',
        visible: () => true,
      },
      {
        name: 'Afkeuren',
        key: 'tab-reject',
        href: '#tab-reject',
        visible: () => this.hasRejectButton,
      },
    ];
  }

  protected get damageTabs(): Tab[] {
    return [
      {
        name: 'Vragenlijst',
        key: 'tab-report',
        href: '#tab-report',
        visible: () => true,
      },
      {
        name: 'Opmerking',
        key: 'tab-remarks',
        href: '#tab-remarks',
        visible: () => true,
      },
      {
        name: 'Reparaties',
        key: 'tab-repairs',
        href: '#tab-repairs',
        visible: () => ! this.report.isVesNulmetingReport,
        click: () => {
          this.getRepairs();
        },
      },
      {
        name: "Foto's",
        key: 'tab-images',
        href: '#tab-images',
        visible: () => true,
      },
      {
        name: 'Zelfopname app',
        key: 'tab-zelfopname',
        href: '#tab-zelfopname',
        visible: () => this.report.isZelfopnameReport,
      },
    ];
  }

  protected get attachmentOptions(): AttachmentOptions {
    return {
      model: this.report,
      type: 'attachments',
      showAddButton: false,
      showTrillingsButton: false,
      headers: [
        {
          text: '',
          value: 'checkbox',
        },
        {
          text: 'Thumbnail',
          value: 'thumbnail',
        },
        {
          text: 'Bijlage',
          value: 'file_name',
        },
        {
          text: 'Beschrijving',
          value: 'description',
        },
        {
          text: 'Geüpload door',
          value: 'uploaded_by',
        },
        {
          text: 'Aangemaakt op',
          value: 'created_at',
        },
        // {
        //   text: 'Acties',
        //   value: 'actions',
        //   class: 'actions',
        // },
      ],
    };
  }
  // END: Add attachment

  protected get isSubmitted() {
    return !! (this.report.submit_count && this.report.submit_count > 0);
  }

  protected get isVeldwerkRejected() {
    return this.report.status === 'veldwerk_rejected' || this.isSubmitted;
  }

  protected get isZienswijzeRejected() {
    return this.report.status === 'zienswijze_veldwerk_rejected' || this.isSubmitted;
  }

  protected get isStuwmeerRejected() {
    return this.report.status === 'smr_veldwerk_rejected' || this.isSubmitted;
  }

  protected get isObjectionRejected() {
    return this.report.status === 'objection_veldwerk_rejected' || this.isSubmitted;
  }

  protected get isVoorControle() {
    return this.report.isVoorControle;
  }

  protected get isSupervisor() {
    return this.report.hasStatus(ReportStatus.SUPERVISOR);
  }

  @ProvideReactive() // Injected at Question.ts
  protected get isImageRecognitionAvaliable(): boolean {
    return !! (this.hasHistoricalReports && this.currentDamageMedia?.length);
  }

  protected get hasHistoricalReports(): boolean {
    return !! this.historicalReports.length;
  }

  protected get currentUserIsReportExpert(): boolean {
    const expert = this.report?.expert || null;
    const authUser = this.$store.state.Auth || null;

    if (! expert || ! authUser) {
      return false;
    }

    return expert?.uuid === authUser.uuid;
  }

  protected get hoorzittingHasPassed(): boolean {
    const plannedAt = this.report.planned_at ? DateTime.fromSQL(this.report.planned_at) : '';
    return DateTime.local() > plannedAt;
  }

  // #endregion

  // #region @Watchers

  @Watch('uploadQueue')
  public uploadQueueChanged(to: File[], from: File[]) {
    if (to.length < from.length) {
      return;
    }

    if (this.postTimeOut) {
      clearTimeout(this.postTimeOut);
    }

    this.postTimeOut = setTimeout(() => {
      this.postFiles();
    }, 20);
  }

  @Watch('currentStep')
  public async currentStepChanged(to: any, from: any) {
    this.formSections = to;
    if (to === 5) {
      this.formSections = [5, 120];
    }
    this.isLoadingQuestions = true;
    await this.getReportQuestions();
    await this.getReportAnswers();
    this.isLoadingQuestions = false;

    Storage.set('expert_currentStep', this.currentStep);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  @Watch('newCurrentStep')
  public async currentNewStepChanged(step: number) {
    const currentSection = this.newSections.filter((section) => section.step === step);

    this.formSections = currentSection[0].sections;

    this.isLoadingQuestions = true;
    await this.getReportQuestions();
    await this.getReportAnswers();
    this.isLoadingQuestions = false;

    Storage.set('expert_new_currentStep', this.newCurrentStep);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  @Watch('isEditingImage')
  public isEditingImageChanged() {
    const body = <HTMLElement>document.querySelector('body');
    if (this.isEditingImage) {
      addClass(body, 'bodyNoScroll');
      return;
    }
    removeClass(body, 'bodyNoScroll');
  }

  @Watch('$route')
  public routeChanged(to: any, from: any) {
    //
  }

  @Watch('activeTab')
  public activeTabChanged() {
    Storage.set('expert_activeTab', this.activeTab);
  }

  @Watch('activeTabSub')
  public activeTabSubChanged() {
    Storage.set('expert_activeTabSub', this.activeTabSub);
  }
}

// #endregion

interface Mask {
  [key: string]: string;
}

interface ModifiedRepair extends Repair {
  is_editing?: boolean;
}

interface SectionItem {
  name: string;
  step: number;
  sections: number[];
}

interface DamageException {
  name: string;
  key: string;
}
