

























import { Component, Vue, PropSync } from 'vue-property-decorator';

@Component<SideDrawer>({})
export default class SideDrawer extends Vue {
  @PropSync('value') syncValue!: boolean;

  protected drawerWidth = 600;

  protected isFullscreen = false;

  protected close() {
    this.$emit('input', false);
  }

  protected input(value: boolean) {
    this.$emit('input', value);
  }

  protected onClickEnterFullscreen() {
    this.drawerWidth = window?.screen?.width || 600;
    this.isFullscreen = true;
  }

  protected onClickExitFullscreen() {
    this.drawerWidth = 600;
    this.isFullscreen = false;
  }
}
