import { Component, Vue, Prop } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { Damage } from '@/models/Damage';
import { RepairSet } from '@/models/RepairSet';
import DataTable, { TableVisibility } from '@/components/data-table/DataTable';
import { Report } from '@/models/Report';
import { Rpc } from '@/models/Rpc';

@Component<RepairSetDialog>({})
export default class RepairSetDialog extends Vue {
  @Prop({ default: false })
  protected value!: boolean;

  @Prop()
  protected damage!: Damage;

  @Prop()
  protected report!: Report;

  protected isLoading = false;

  protected selectedRepairSet: RepairSet | null = null;

  protected selectedOptionalRepairs: string[] = [];

  protected stepper = 0;

  protected close() {
    this.$emit('close');
    this.$emit('input', false);
  }

  protected visibility: TableVisibility = {
    checkboxes: false,
    total: true,
    title: false,
  };

  protected get tableOptions(): Object {
    return {
      model: new RepairSet().filter({ calculation_model: this.report?.calculation_model?.uuid }).include(['required_repairs', 'optional_repairs']),
      headers: [
        {
          text: 'Onderwerp',
          value: 'name',

          width: '30%',
        },
        {
          text: 'inhoud',
          value: 'description',
        },
      ],
      actions: [
        {
          type: 'view',
          label: 'view',
          icon: 'add',
          action: (dataTable: DataTable, repairSet: RepairSet) => {
            this.selectedRepairSet = repairSet;
            this.stepper = 2;
          },
          tooltip: 'Voeg deze reparatieset toe',
        },
      ],
      filter: [],
    };
  }

  protected addRepairSet() {
    if (! this.selectedRepairSet) {
      return;
    }

    this.isLoading = true;

    const payload: {[key: string]: any} = {
      signature: 'damage:create-repairs-from-set',
      body: {
        damage: this.damage.uuid,
        repair_set: this.selectedRepairSet.id,
        optionalRepairs: this.selectedOptionalRepairs,
      },
    };

    new Rpc()
      .rpcPost(payload)
      .then(() => {
        this.close();
        this.$emit('reload');
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }
}
