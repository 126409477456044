export const GeneralKeys: GeneralKey = {
  SchadeadresStraat: {
    value: '',
    key: 'SchadeadresStraat',
  },
  SchadeadresHuisnummer: {
    value: '',
    key: 'SchadeadresHuisnummer',
  },
  SchadeadresHuisnummerToevoeging: {
    value: '',
    key: 'SchadeadresHuisnummerToevoeging',
  },
  SchadeadresPlaats: {
    value: '',
    key: 'SchadeadresPlaats',
  },
  SchadeadresPostcode: {
    value: '',
    key: 'SchadeadresPostcode',
  },
  DatumAanvraag: {
    value: '',
    key: 'DatumAanvraag',
  },
  BouwJaar: {
    value: '',
    key: 'BouwJaar',
  },
  EigendomSinds: {
    value: '',
    key: 'EigendomSinds',
  },
  storyOfApplicant: {
    value: '',
    key: 'storyOfApplicant',
  },
  ExpertComment: {
    value: '',
    key: 'ExpertComment',
  },
  OpmerkingDeskundige: {
    value: '',
    key: 'OpmerkingDeskundige',
  },
  OpmerkingAanvrager: {
    value: '',
    key: 'OpmerkingAanvrager',
  },
  presetAtTaxations_Name: {
    value: '',
    key: 'presetAtTaxations_Name',
  },
  SoortGebouw: {
    value: '',
    key: 'SoortGebouw',
  },
  relevantRemarksApplicant: {
    value: '',
    key: 'relevantRemarksApplicant',
  },
  TelefoonIntermediair1: {
    value: '',
    key: 'TelefoonIntermediair1',
  },
};

export interface GeneralKey {
  [key: string]: GeneralKeyContent;
}
export interface GeneralKeyContent {
  value: string;
  key: string;
}
