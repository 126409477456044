import { Component, Vue, Prop } from 'vue-property-decorator';
import { Damage, DamageExpertRemark } from '@/models/Damage';
import QuillEditor from '@/components/quill-editor/QuillEditor.vue';
import ErrorHandler from '@/support/ErrorHandler';
import {cloneDeep, debounce} from 'lodash';
import DamageRemarkField from './DamageRemarkField/DamageRemarkField.vue';

@Component<DamageRemarks>({
  components: {
    QuillEditor,
    DamageRemarkField,
  },
})
export default class DamageRemarks extends Vue {
  @Prop()
  protected damage!: Damage;

  @Prop({ default: false })
  protected disabled!: boolean;

  protected remark: DamageExpertRemark = {};

  protected mounted(): void {
    this.remark = this.damage.expert_remark ? cloneDeep(this.damage.expert_remark) : {};
  }

  protected async handleFieldChanged(value: string | boolean, key: string): Promise<void> {
    const expert_remark: { [key: string]: string | boolean } = {};

    expert_remark[key] = value;

    try {
      await this.damage?.update({ expert_remark });
    } catch (error) {
      ErrorHandler.network(error);
    }
  }

  protected handleNotesChanged = debounce(() => {
    const expert_remarks = this.damage.expert_remarks;
    try {
      this.damage?.update({expert_remarks});
    } catch (error) {
      ErrorHandler.network(error);
    }
  }, 500);

  protected get outsideFacadeSquareMeters(): string {
    const surface = Number(this.remark.outside_surface_facade_length) * Number(this.remark.outside_surface_facade_width);
    return ! Number.isNaN(surface) ? surface.toString() : '';
  }

  protected get insideFloorAreaSquareMeters(): string {
    const surface = Number(this.remark.inside_floor_area_length) * Number(this.remark.inside_floor_area_width);
    return ! Number.isNaN(surface) ? surface.toString() : '';
  }

  protected get insideWallSizeSquareMeters(): string {
    const surface = Number(this.remark.inside_wall_size_length) * Number(this.remark.inside_wall_size_width);
    return ! Number.isNaN(surface) ? surface.toString() : '';
  }

  protected get insideOpeningSizeSquareMeters(): string {
    const surface = Number(this.remark.inside_opening_size_length) * Number(this.remark.inside_opening_size_width);
    return ! Number.isNaN(surface) ? surface.toString() : '';
  }
}
