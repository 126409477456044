var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    _vm._b(
      {
        staticClass: "navigation-drawer-custom",
        attrs: {
          touchless: true,
          absolute: "",
          right: "",
          width: _vm.drawerWidth
        },
        on: { input: _vm.input },
        model: {
          value: _vm.syncValue,
          callback: function($$v) {
            _vm.syncValue = $$v
          },
          expression: "syncValue"
        }
      },
      "v-navigation-drawer",
      _vm.$attrs,
      false
    ),
    [
      _c(
        "div",
        { staticClass: "drawer-header" },
        [
          _c(
            "h2",
            { staticClass: "elementSubTitle pa-2" },
            [
              !_vm.isFullscreen
                ? _c("v-icon", { on: { click: _vm.onClickEnterFullscreen } }, [
                    _vm._v("fullscreen")
                  ])
                : _vm._e(),
              _vm.isFullscreen
                ? _c("v-icon", { on: { click: _vm.onClickExitFullscreen } }, [
                    _vm._v("fullscreen_exit")
                  ])
                : _vm._e(),
              _vm._t("title")
            ],
            2
          ),
          _c(
            "v-icon",
            { staticClass: "drawer-toggle", on: { click: _vm.close } },
            [_vm._v("close")]
          )
        ],
        1
      ),
      _c("div", { staticClass: "drawer-content" }, [_vm._t("content")], 2)
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }