var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.value
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "700px", fullscreen: true },
              model: {
                value: _vm.value,
                callback: function($$v) {
                  _vm.value = $$v
                },
                expression: "value"
              }
            },
            [
              _c(
                "DefaultDialog",
                {
                  on: {
                    close: function($event) {
                      return _vm.close()
                    }
                  }
                },
                [
                  _c("template", { slot: "header" }, [
                    _c("h2", [_vm._v("Schades Importeren")])
                  ]),
                  _c(
                    "template",
                    { slot: "content" },
                    [
                      _vm.isLoading
                        ? _c("LoaderCard", {
                            attrs: { flat: "", type: "spinner--center" }
                          })
                        : _vm._e(),
                      !_vm.isLoading
                        ? [
                            _c(
                              "v-flex",
                              { attrs: { xs12: "" } },
                              [
                                _c("v-autocomplete", {
                                  staticClass: "with-border",
                                  attrs: {
                                    items: _vm.reports,
                                    "item-text": "case_number",
                                    "item-value": "uuid",
                                    "hide-details": "",
                                    placeholder: "kies een dossier",
                                    "search-input": _vm.searchInput,
                                    loading: _vm.isLoadingReports
                                  },
                                  on: {
                                    "update:searchInput": function($event) {
                                      _vm.searchInput = $event
                                    },
                                    "update:search-input": function($event) {
                                      _vm.searchInput = $event
                                    },
                                    keyup: _vm.debouncedSearch,
                                    change: _vm.loadDamages
                                  },
                                  model: {
                                    value: _vm.selectedReport,
                                    callback: function($$v) {
                                      _vm.selectedReport = $$v
                                    },
                                    expression: "selectedReport"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              { attrs: { xs12: "" } },
                              [
                                _c("v-checkbox", {
                                  attrs: {
                                    label:
                                      "Schades inclusief foto's importeren",
                                    primary: "",
                                    "hide-details": ""
                                  },
                                  model: {
                                    value: _vm.withPictures,
                                    callback: function($$v) {
                                      _vm.withPictures = $$v
                                    },
                                    expression: "withPictures"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              { attrs: { xs12: "" } },
                              [
                                _c("v-checkbox", {
                                  attrs: {
                                    label:
                                      "Schades inclusief reparaties importeren",
                                    primary: "",
                                    "hide-details": ""
                                  },
                                  model: {
                                    value: _vm.withRepairs,
                                    callback: function($$v) {
                                      _vm.withRepairs = $$v
                                    },
                                    expression: "withRepairs"
                                  }
                                })
                              ],
                              1
                            ),
                            !_vm.isLoadingDamages &&
                            _vm.selectedReportDamages &&
                            _vm.selectedReportDamages.length
                              ? [
                                  _c(
                                    "h2",
                                    {
                                      staticClass:
                                        "elementPanel--subPanel--title pt-3"
                                    },
                                    [_vm._v("Selecteer schades")]
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "damage-scroll-container",
                                      attrs: { xs12: "", "pb-5": "" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "fill-height relative overflow-y"
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "damage-container" },
                                            [
                                              _c("DamagePanel", {
                                                ref: "damagePanel",
                                                attrs: {
                                                  damages:
                                                    _vm.selectedReportDamages,
                                                  includes: [
                                                    "overrule_reasons",
                                                    "reject_reasons",
                                                    "media",
                                                    "repairs"
                                                  ],
                                                  visibility:
                                                    _vm.damageVisibility
                                                },
                                                on: {
                                                  emitSelectedDamages:
                                                    _vm.setSelectedDamages
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              : _vm._e()
                          ]
                        : _vm._e()
                    ],
                    2
                  ),
                  _c(
                    "template",
                    { slot: "actions" },
                    [
                      !_vm.isLoading
                        ? _c(
                            "v-layout",
                            {
                              attrs: { "justify-end": "", "align-center": "" }
                            },
                            [
                              _c(
                                "v-flex",
                                { attrs: { shrink: "" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "fadedText link mr-3",
                                      on: {
                                        click: function($event) {
                                          return _vm.close()
                                        }
                                      }
                                    },
                                    [_vm._v("Annuleren")]
                                  ),
                                  _c(
                                    "MiButton",
                                    {
                                      attrs: {
                                        disabled:
                                          !_vm.selectedReport ||
                                          !_vm.selectedDamages ||
                                          !_vm.selectedDamages.length,
                                        color: "primary",
                                        small: "true"
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.importDamages()
                                        }
                                      }
                                    },
                                    [_vm._v(" Importeren ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }