var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "damageRemarks", class: { disabled: _vm.disabled } },
    [
      _c("ATitle", { staticClass: "damageRemarks_group-title" }, [
        _vm._v("Buiten")
      ]),
      _c(
        "DamageRemarkField",
        { attrs: { label: "Oppervlakte gevel" } },
        [
          _c("v-text-field", {
            staticClass: "damageRemarks__field-input",
            attrs: {
              type: "number",
              min: "0",
              disabled: _vm.disabled,
              label: "Lengte",
              "hide-details": ""
            },
            on: {
              change: function($event) {
                return _vm.handleFieldChanged(
                  $event,
                  "outside_surface_facade_length"
                )
              }
            },
            model: {
              value: _vm.remark.outside_surface_facade_length,
              callback: function($$v) {
                _vm.$set(_vm.remark, "outside_surface_facade_length", $$v)
              },
              expression: "remark.outside_surface_facade_length"
            }
          }),
          _c("v-text-field", {
            staticClass: "damageRemarks__field-input",
            attrs: {
              type: "number",
              min: "0",
              disabled: _vm.disabled,
              label: "Breedte",
              "hide-details": ""
            },
            on: {
              change: function($event) {
                return _vm.handleFieldChanged(
                  $event,
                  "outside_surface_facade_width"
                )
              }
            },
            model: {
              value: _vm.remark.outside_surface_facade_width,
              callback: function($$v) {
                _vm.$set(_vm.remark, "outside_surface_facade_width", $$v)
              },
              expression: "remark.outside_surface_facade_width"
            }
          }),
          _c("v-text-field", {
            staticClass: "damageRemarks__field-input",
            attrs: {
              value: _vm.outsideFacadeSquareMeters,
              disabled: "",
              label: "Oppervlakte",
              suffix: "m²",
              "hide-details": ""
            }
          })
        ],
        1
      ),
      _c(
        "DamageRemarkField",
        { attrs: { label: "Lagen en strekken (gevelsteen)" } },
        [
          _c("v-text-field", {
            staticClass: "damageRemarks__field-input",
            attrs: {
              type: "number",
              min: "0",
              disabled: _vm.disabled,
              label: "Lagen",
              "hide-details": ""
            },
            on: {
              change: function($event) {
                return _vm.handleFieldChanged(
                  $event,
                  "outside_facing_brick_layers"
                )
              }
            },
            model: {
              value: _vm.remark.outside_facing_brick_layers,
              callback: function($$v) {
                _vm.$set(_vm.remark, "outside_facing_brick_layers", $$v)
              },
              expression: "remark.outside_facing_brick_layers"
            }
          }),
          _c("v-text-field", {
            staticClass: "damageRemarks__field-input",
            attrs: {
              type: "number",
              min: "0",
              disabled: _vm.disabled,
              label: "Strekken",
              "hide-details": ""
            },
            on: {
              change: function($event) {
                return _vm.handleFieldChanged(
                  $event,
                  "outside_facing_brick_stretches"
                )
              }
            },
            model: {
              value: _vm.remark.outside_facing_brick_stretches,
              callback: function($$v) {
                _vm.$set(_vm.remark, "outside_facing_brick_stretches", $$v)
              },
              expression: "remark.outside_facing_brick_stretches"
            }
          })
        ],
        1
      ),
      _c(
        "DamageRemarkField",
        { attrs: { label: "Eerder hersteld (is dit zichtbaar?)" } },
        [
          _c(
            "v-radio-group",
            {
              staticClass: "damageRemarks__radio-group",
              attrs: { disabled: _vm.disabled },
              on: {
                change: function($event) {
                  return _vm.handleFieldChanged(
                    $event,
                    "outside_previously_visibly_restored"
                  )
                }
              },
              model: {
                value: _vm.remark.outside_previously_visibly_restored,
                callback: function($$v) {
                  _vm.$set(
                    _vm.remark,
                    "outside_previously_visibly_restored",
                    $$v
                  )
                },
                expression: "remark.outside_previously_visibly_restored"
              }
            },
            [
              _c("v-radio", {
                staticClass: "damageRemarks__radio-input",
                attrs: { value: true, label: "Ja", color: "primary" }
              }),
              _c("v-radio", {
                staticClass: "damageRemarks__radio-input",
                attrs: { value: false, label: "Nee", color: "primary" }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("ATitle", { staticClass: "damageRemarks_group-title" }, [
        _vm._v("Binnen")
      ]),
      _c(
        "DamageRemarkField",
        { attrs: { label: "Vloeroppervlakte" } },
        [
          _c("v-text-field", {
            staticClass: "damageRemarks__field-input",
            attrs: {
              type: "number",
              min: "0",
              disabled: _vm.disabled,
              label: "Lengte",
              "hide-details": ""
            },
            on: {
              change: function($event) {
                return _vm.handleFieldChanged(
                  $event,
                  "inside_floor_area_length"
                )
              }
            },
            model: {
              value: _vm.remark.inside_floor_area_length,
              callback: function($$v) {
                _vm.$set(_vm.remark, "inside_floor_area_length", $$v)
              },
              expression: "remark.inside_floor_area_length"
            }
          }),
          _c("v-text-field", {
            staticClass: "damageRemarks__field-input",
            attrs: {
              type: "number",
              min: "0",
              disabled: _vm.disabled,
              label: "Breedte",
              "hide-details": ""
            },
            on: {
              change: function($event) {
                return _vm.handleFieldChanged($event, "inside_floor_area_width")
              }
            },
            model: {
              value: _vm.remark.inside_floor_area_width,
              callback: function($$v) {
                _vm.$set(_vm.remark, "inside_floor_area_width", $$v)
              },
              expression: "remark.inside_floor_area_width"
            }
          }),
          _c("v-text-field", {
            staticClass: "damageRemarks__field-input",
            attrs: {
              value: _vm.insideFloorAreaSquareMeters,
              disabled: "",
              label: "Oppervlakte",
              suffix: "m²",
              "hide-details": ""
            }
          })
        ],
        1
      ),
      _c(
        "DamageRemarkField",
        { attrs: { label: "Afmeting volledige wand incl. Openingen" } },
        [
          _c("v-text-field", {
            staticClass: "damageRemarks__field-input",
            attrs: {
              type: "number",
              min: "0",
              disabled: _vm.disabled,
              label: "Lengte",
              "hide-details": ""
            },
            on: {
              change: function($event) {
                return _vm.handleFieldChanged($event, "inside_wall_size_length")
              }
            },
            model: {
              value: _vm.remark.inside_wall_size_length,
              callback: function($$v) {
                _vm.$set(_vm.remark, "inside_wall_size_length", $$v)
              },
              expression: "remark.inside_wall_size_length"
            }
          }),
          _c("v-text-field", {
            staticClass: "damageRemarks__field-input",
            attrs: {
              type: "number",
              min: "0",
              disabled: _vm.disabled,
              label: "Breedte",
              "hide-details": ""
            },
            on: {
              change: function($event) {
                return _vm.handleFieldChanged($event, "inside_wall_size_width")
              }
            },
            model: {
              value: _vm.remark.inside_wall_size_width,
              callback: function($$v) {
                _vm.$set(_vm.remark, "inside_wall_size_width", $$v)
              },
              expression: "remark.inside_wall_size_width"
            }
          }),
          _c("v-text-field", {
            staticClass: "damageRemarks__field-input",
            attrs: {
              value: _vm.insideWallSizeSquareMeters,
              disabled: "",
              label: "Oppervlakte",
              suffix: "m²",
              "hide-details": ""
            }
          })
        ],
        1
      ),
      _c(
        "DamageRemarkField",
        { attrs: { label: "Lengte overige wanden (ook indien geen schade);" } },
        [
          _c("v-text-field", {
            staticClass: "damageRemarks__field-input",
            attrs: {
              disabled: _vm.disabled,
              label: "Lengte",
              "hide-details": ""
            },
            on: {
              change: function($event) {
                return _vm.handleFieldChanged(
                  $event,
                  "inside_other_walls_length"
                )
              }
            },
            model: {
              value: _vm.remark.inside_other_walls_length,
              callback: function($$v) {
                _vm.$set(_vm.remark, "inside_other_walls_length", $$v)
              },
              expression: "remark.inside_other_walls_length"
            }
          })
        ],
        1
      ),
      _c(
        "DamageRemarkField",
        { attrs: { label: "Afmeting openingen" } },
        [
          _c("v-text-field", {
            staticClass: "damageRemarks__field-input",
            attrs: {
              type: "number",
              min: "0",
              disabled: _vm.disabled,
              label: "Lengte",
              "hide-details": ""
            },
            on: {
              change: function($event) {
                return _vm.handleFieldChanged(
                  $event,
                  "inside_opening_size_length"
                )
              }
            },
            model: {
              value: _vm.remark.inside_opening_size_length,
              callback: function($$v) {
                _vm.$set(_vm.remark, "inside_opening_size_length", $$v)
              },
              expression: "remark.inside_opening_size_length"
            }
          }),
          _c("v-text-field", {
            staticClass: "damageRemarks__field-input",
            attrs: {
              type: "number",
              min: "0",
              disabled: _vm.disabled,
              label: "Breedte",
              "hide-details": ""
            },
            on: {
              change: function($event) {
                return _vm.handleFieldChanged(
                  $event,
                  "inside_opening_size_width"
                )
              }
            },
            model: {
              value: _vm.remark.inside_opening_size_width,
              callback: function($$v) {
                _vm.$set(_vm.remark, "inside_opening_size_width", $$v)
              },
              expression: "remark.inside_opening_size_width"
            }
          }),
          _c("v-text-field", {
            staticClass: "damageRemarks__field-input",
            attrs: {
              value: _vm.insideOpeningSizeSquareMeters,
              disabled: "",
              label: "Oppervlakte",
              suffix: "m²",
              "hide-details": ""
            }
          })
        ],
        1
      ),
      _c(
        "DamageRemarkField",
        { attrs: { label: "Soort wand(en) (overige wanden)" } },
        [
          _c("v-text-field", {
            staticClass: "damageRemarks__field-input wide",
            attrs: {
              disabled: _vm.disabled,
              label: "Soort wand(en)",
              "hide-details": ""
            },
            on: {
              change: function($event) {
                return _vm.handleFieldChanged($event, "inside_type_of_walls")
              }
            },
            model: {
              value: _vm.remark.inside_type_of_walls,
              callback: function($$v) {
                _vm.$set(_vm.remark, "inside_type_of_walls", $$v)
              },
              expression: "remark.inside_type_of_walls"
            }
          })
        ],
        1
      ),
      _c(
        "DamageRemarkField",
        {
          attrs: {
            label: "Bijzonderheden (toilet, wastafel, verwarmingselement);"
          }
        },
        [
          _c("v-text-field", {
            staticClass: "damageRemarks__field-input wide",
            attrs: {
              disabled: _vm.disabled,
              label: "Bijzonderheden",
              "hide-details": ""
            },
            on: {
              change: function($event) {
                return _vm.handleFieldChanged($event, "inside_particularities")
              }
            },
            model: {
              value: _vm.remark.inside_particularities,
              callback: function($$v) {
                _vm.$set(_vm.remark, "inside_particularities", $$v)
              },
              expression: "remark.inside_particularities"
            }
          })
        ],
        1
      ),
      _c(
        "DamageRemarkField",
        {
          attrs: {
            label: "Scheur in kanaalplaat (aangeven lopen deze vanaf de wand)."
          }
        },
        [
          _c("v-text-field", {
            staticClass: "damageRemarks__field-input wide",
            attrs: {
              disabled: _vm.disabled,
              label: "Scheur in kanaalplaat",
              "hide-details": ""
            },
            on: {
              change: function($event) {
                return _vm.handleFieldChanged(
                  $event,
                  "inside_crack_hollowcore_slab"
                )
              }
            },
            model: {
              value: _vm.remark.inside_crack_hollowcore_slab,
              callback: function($$v) {
                _vm.$set(_vm.remark, "inside_crack_hollowcore_slab", $$v)
              },
              expression: "remark.inside_crack_hollowcore_slab"
            }
          })
        ],
        1
      ),
      _c(
        "DamageRemarkField",
        { attrs: { label: "Notities" } },
        [
          _c("QuillEditor", {
            staticClass: "damageRemarks__textarea-input",
            attrs: { disabled: _vm.disabled },
            on: {
              change: function($event) {
                return _vm.handleNotesChanged()
              }
            },
            model: {
              value: _vm.damage.expert_remarks,
              callback: function($$v) {
                _vm.$set(_vm.damage, "expert_remarks", $$v)
              },
              expression: "damage.expert_remarks"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }