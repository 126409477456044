var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "DefaultDialog",
        { on: { close: _vm.close } },
        [
          _c("template", { slot: "header" }, [
            _vm._v(
              _vm._s(
                _vm.selectedRepairSet
                  ? _vm.selectedRepairSet.name
                  : "Kies een reparatieset"
              )
            )
          ]),
          _c(
            "template",
            { slot: "content" },
            [
              _vm.isLoading
                ? _c("LoaderCard", {
                    attrs: { flat: "", type: "spinner--center" }
                  })
                : _vm._e(),
              !_vm.isLoading
                ? [
                    !_vm.selectedRepairSet
                      ? _c("DataTable", {
                          ref: "repairSetDatatable",
                          attrs: {
                            options: _vm.tableOptions,
                            visibility: _vm.visibility
                          }
                        })
                      : _vm._e(),
                    _vm.selectedRepairSet
                      ? [
                          _c("v-layout", [
                            _c(
                              "div",
                              {
                                staticClass: "sidepanel__content",
                                attrs: { xs12: "", "align-center": "" }
                              },
                              [
                                _c("div", { staticClass: "d-inline-block" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "router-back",
                                      on: {
                                        click: function($event) {
                                          _vm.selectedRepairSet = null
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", [_vm._v("chevron_left")]),
                                      _vm._v(" Terug naar overzicht ")
                                    ],
                                    1
                                  )
                                ])
                              ]
                            )
                          ]),
                          _c(
                            "v-layout",
                            { staticClass: "item__list", attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { staticClass: "label", attrs: { xs12: "" } },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { wrap: "", "align-center": "" } },
                                    [
                                      _c("v-flex", { attrs: { xs1: "" } }),
                                      _c("v-flex", { attrs: { xs9: "" } }, [
                                        _vm._v("Naam")
                                      ]),
                                      _c("v-flex", { attrs: { xs1: "" } }, [
                                        _vm._v("Eenheid")
                                      ]),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            xs1: "",
                                            "text-xs-right": ""
                                          }
                                        },
                                        [_vm._v("BTW %")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "h4",
                                { staticClass: "text__primary mt-1 mb-2" },
                                [_vm._v("Verplicht")]
                              ),
                              _vm._l(
                                _vm.selectedRepairSet.required_repairs,
                                function(required, requiredIndex) {
                                  return _c(
                                    "v-flex",
                                    {
                                      key: "required-" + requiredIndex,
                                      staticClass: "list__item",
                                      attrs: { xs12: "" }
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        {
                                          attrs: {
                                            wrap: "",
                                            "align-center": ""
                                          }
                                        },
                                        [
                                          _c("v-flex", { attrs: { xs1: "" } }),
                                          _c("v-flex", { attrs: { xs9: "" } }, [
                                            _vm._v(_vm._s(required.name))
                                          ]),
                                          _c("v-flex", { attrs: { xs1: "" } }, [
                                            _vm._v(_vm._s(required.unit))
                                          ]),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                xs1: "",
                                                "text-xs-right": ""
                                              }
                                            },
                                            [_vm._v(_vm._s(required.vat) + "%")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }
                              ),
                              _c(
                                "h4",
                                { staticClass: "text__primary mt-1 mb-2" },
                                [_vm._v("Optioneel")]
                              ),
                              _vm._l(
                                _vm.selectedRepairSet.optional_repairs,
                                function(optional, optionalIndex) {
                                  return _c(
                                    "v-flex",
                                    {
                                      key: "optional-" + optionalIndex,
                                      staticClass: "list__item",
                                      attrs: { xs12: "" }
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        {
                                          attrs: {
                                            wrap: "",
                                            "align-center": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs1: "" } },
                                            [
                                              _c("v-checkbox", {
                                                staticClass: "pa-0 ma-0",
                                                attrs: {
                                                  "hide-details": "",
                                                  name: "useAllowance",
                                                  value: optional.uuid,
                                                  color: "#a09b1b"
                                                },
                                                model: {
                                                  value:
                                                    _vm.selectedOptionalRepairs,
                                                  callback: function($$v) {
                                                    _vm.selectedOptionalRepairs = $$v
                                                  },
                                                  expression:
                                                    "selectedOptionalRepairs"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c("v-flex", { attrs: { xs9: "" } }, [
                                            _vm._v(_vm._s(optional.name))
                                          ]),
                                          _c("v-flex", { attrs: { xs1: "" } }, [
                                            _vm._v(_vm._s(optional.unit))
                                          ]),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                xs1: "",
                                                "text-xs-right": ""
                                              }
                                            },
                                            [_vm._v(_vm._s(optional.vat) + "%")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }
                              )
                            ],
                            2
                          )
                        ]
                      : _vm._e()
                  ]
                : _vm._e()
            ],
            2
          ),
          _c(
            "template",
            { slot: "actions" },
            [
              !_vm.isLoading && _vm.selectedRepairSet
                ? _c(
                    "v-layout",
                    { attrs: { "justify-end": "", "align-center": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { shrink: "" } },
                        [
                          [
                            _c(
                              "span",
                              {
                                staticClass: "fadedText link mr-3",
                                on: { click: _vm.close }
                              },
                              [_vm._v("Annuleren")]
                            ),
                            _c(
                              "MiButton",
                              {
                                attrs: { color: "primary", small: "true" },
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.addRepairSet($event)
                                  }
                                }
                              },
                              [_vm._v("Toevoegen")]
                            )
                          ]
                        ],
                        2
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }